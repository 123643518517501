import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useTranslation } from "react-i18next";
import TableField from "./TableField";
import { Stack } from "@mui/material";

export default function SecondAttachTable({
  zipFile,
  setZipFile,
  show
}) {
  const { t } = useTranslation();
  const [selectedImage, setSelectedImage] = React.useState(null);

  const handleImageClick = (file) => {
    setSelectedImage(`https://dashboard.nmedical.co/${file.attachment}`);
  };

  // Function to close modal
  const closeModal = () => {
    setSelectedImage(null);
  };
  return (
    <TableContainer
      component={Paper}
      sx={{ width: "100%", boxShadow: "none", direction: "ltr" }}
    >
      <Table
        sx={{
          border: "1.5px solid #008080",
          ".MuiTableCell-root": { fontFamily: '"Quicksand", sans-serif' },
        }}
      >
        <TableHead sx={{ bgcolor: "#008080" }}>
          <TableRow>
            <TableCell colSpan={4} sx={{ color: "white", fontSize: "15px" }}>
              {t("Attachments")}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
      
            <TableRow>
              <TableCell
                colSpan={1}
                component="th"
                scope="row"
                sx={{
                  color: "#464646",
                  fontSize: "15px",
                  fontWeight: "bold",
                  alignContent: "start",
                }}
              >
                Images :
              </TableCell>

              <TableCell align="start" sx={{ alignItems: "start" }}>
                <Stack
                  direction="row"
                  gap={2}
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "start",
                    alignItems: "center",
                  }}
                >    
                 {!show &&
                  zipFile && zipFile.map((file, index) => (
                    <img
                      key={index}
                      src={`https://dashboard.nmedical.co/${file.attachment}`}
                      style={{ width: "100px", height: "100px", objectFit: "cover", cursor:'pointer' }}
                      onClick={() => handleImageClick(file)}
                    />
                  ))}
                   {selectedImage && (
        <div 
          style={{
            position: "fixed", 
            top: 0, 
            left: 0, 
            width: "100%", 
            height: "100%", 
            backgroundColor: "rgba(0, 0, 0, 0.8)", 
            display: "flex", 
            justifyContent: "center", 
            alignItems: "center"
          }}
          onClick={closeModal}
        >
          <img 
            src={selectedImage} 
            alt="enlarged"
            style={{ maxWidth: "90%", maxHeight: "90%" }} 
          />
        </div>
      )}
                   {show && (
                  <TableField
                    onChange={setZipFile}
                    value={zipFile}
                    h={"30px"}
                    width={"500px"}
                    allowMultiple={true}
                    zipFile={false}
                    type={'file'}
                  />
                )}
                </Stack>
              </TableCell>
            </TableRow>
         
        </TableBody>
      </Table>
    </TableContainer>
  );
}
