import {
  Alert,
  Button,
  Container,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import pic1 from "../Media/name.png";
import pic2 from "../Media/email.png";
import pic3 from "../Media/phone.png";
import pic4 from "../Media/msg.png";
import TextFieldComp from "./TextFieldComp";
import pic from "../Media/Group.png";
import axios from "axios";
import Success from "./Success";

export default function Support({contactDescription}) {
  const { t } = useTranslation();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [alerting, setAlerting] = useState(false);
  const [openSuccess,setOpenSuccess] = useState(false)
  const [lang, setLang] = useState(localStorage.getItem("lang"));

  const matches = useMediaQuery("(min-width:700px)");
  const handleSend = () => {
    if (name && email && phone && message) {
      const formData = new FormData();
      formData.append('name', name);
      formData.append('phone', phone);
      formData.append('email', email);
      formData.append('message', message);
      axios.post(`${process.env.REACT_APP_API_URL}support-form`,formData, {
        headers: {
          "Accept-Language": lang,
        },
      }).then(
        res =>{
          if(res.data.status === true){
            setOpenSuccess(true)
            setName('')
            setPhone('')
            setEmail('')
            setMessage('')
            setAlerting(false)
            setTimeout(() => {
              window.location.reload(true)
              }, 2100);
          }
        }
      )
    } else {
      setAlerting(true);
    }
  };
  return (
    <div style={{ marginTop: "100px", position: "relative" }} id="support">
      <Stack
        direction="row"
        gap={1}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexWrap: "wrap",
        }}
      >
        <Typography sx={{ fontSize: "30px" ,textAlign:'center'}}>{t("Reach Our")} </Typography>
        <Typography sx={{ color: "#64c6c2", fontSize: "30px" ,textAlign:'center'}}>
          {t("Help Desk")}
        </Typography>
        <Typography sx={{ fontSize: "30px",textAlign:'center' }}>{t("For Support")} </Typography>
      </Stack>
      <Container maxWidth="sm" sx={{ textAlign: "center" }}>
        <p style={{ color: "#B0B0B0" }}>
          {contactDescription}
        </p>
      </Container>
      <Container maxWidth="md">
        <Stack
          direction="row"
          gap={matches?4.5:1}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexWrap: "wrap",
          }}
        >
          <TextFieldComp
            place={"Enter Your Name"}
            onChange={setName}
            value={name}
            h={"50px"}
            width={"260px"}
            im={pic1}
          />
          <TextFieldComp
            place={"Enter Your Email Address"}
            onChange={setEmail}
            value={email}
            h={"50px"}
            width={"260px"}
            type={"email"}
            im={pic2}
          />
          <TextFieldComp
            place={"+972 xxx xxx"}
            onChange={setPhone}
            value={phone}
            h={"50px"}
            width={"260px"}
            im={pic3}
            type={'number'}
          />
        </Stack>
        <TextFieldComp
          place={"Enter Your Message"}
          onChange={setMessage}
          value={message}
          h={"30vh"}
          width={"100%"}
          im={pic4}
          type={"msg"}
        />
        {alerting && (
          <Alert
            severity="error"
            sx={{ width: matches ? "420px" : "310px", marginTop: "20px" }}
          >
            {t('All fields must be filled.')}
          </Alert>
        )}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Button
            sx={{
              backgroundImage: "linear-gradient(to right, #64C6C6 , #008080)",
              color: "white",
              borderRadius: "10px",
              textTransform: "none",
              fontSize: "15px",
              p: "5px",
              minWidth: "150px",
              mt: "20px",
              textAlign:'center'
          }}
            onClick={handleSend}
          >
            {t('Send')}
          </Button>
        </div>
      </Container>
      <img
        src={pic}
        style={{
          right: matches ? "100px" : "50px",
          position: "absolute",
          top: matches ? "" : "105%",
        }}
      />

<Success open={openSuccess} setOpen={setOpenSuccess} msg={'Your message has been successfully send.'} />
    </div>
  );
}
