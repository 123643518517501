import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useTranslation } from "react-i18next";
import TableField from "./TableField";
import { useSelector } from "react-redux";

export default function SecondGeneralTable({
  physician2,
  setPhysician2,
  setCalibration,
  calibration,
  hr2,
  setHr2,
  bp12,
  setBp12,
  bp22,
  setBp22,
  spo22,
  setSpo22,
  show,
  setReason,
  reason,
  setCalibration2,
  calibration2
}) {
  const { t } = useTranslation();

  return (
    <TableContainer
      component={Paper}
      sx={{ width: "100%", boxShadow: "none", direction: "ltr" }}
    >
      <Table
        sx={{
          border: "1.5px solid #008080",
          ".MuiTableCell-root": { fontFamily: '"Quicksand", sans-serif' },
        }}
      >
        <TableHead sx={{ bgcolor: "#008080" }}>
          <TableRow>
            <TableCell colSpan={4} sx={{ color: "white", fontSize: "15px" }}>
              {t("General")}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!show && physician2 ? (
            <TableRow
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell
                component="th"
                scope="row"
                sx={{
                  color: "#464646",
                  fontSize: "15px",
                  fontWeight: "bold",
                  borderBottom: "none",
                }}
              >
                Referring Physician
              </TableCell>
              <TableCell
                align="start"
                sx={{ alignItems: "start", borderBottom: "none" }}
                colSpan={3}
              >
                <p className="p_text">{physician2}</p>
              </TableCell>
            </TableRow>
          ) : null}

          {show ? (
            <TableRow
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell
                component="th"
                scope="row"
                sx={{
                  color: "#464646",
                  fontSize: "15px",
                  fontWeight: "bold",
                  borderBottom: "none",
                }}
              >
                Referring Physician
              </TableCell>
              <TableCell
                align="start"
                sx={{ alignItems: "start", borderBottom: "none" }}
                colSpan={3}
              >
                <TableField
                  onChange={setPhysician2}
                  value={physician2}
                  h={"40px"}
                  width={"100%"}
                  type={"text"}
                />
              </TableCell>
            </TableRow>
          ) : null}


          {!show && calibration && calibration2 ? (
            <TableRow
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell
                component="th"
                scope="row"
                sx={{
                  color: "#464646",
                  fontSize: "15px",
                  fontWeight: "bold",
                  borderBottom: "none",
                }}
              >
                Calibration
              </TableCell>
              <TableCell
                align="start"
                sx={{ alignItems: "start", borderBottom: "none" , display:'flex',flexDirection:'row', gap:'20px'}}
              >
                <p className="p_text">{calibration}</p>
                <p style={{ fontWeight: "bolder" }}>/</p>
                <p className="p_text">{calibration2}</p>
              </TableCell>
            
            </TableRow>
          ) : null}

          {show ? (
            <TableRow
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell
                component="th"
                scope="row"
                sx={{
                  color: "#464646",
                  fontSize: "15px",
                  fontWeight: "bold",
                  borderBottom: "none",
                }}
              >
                Calibration
              </TableCell>
              <TableCell
                align="start"
                sx={{ alignItems: "start", borderBottom: "none" }}
              >
                <TableField
                  handle={true}
                  range={{ min: 1, max: 250 }}
                  onChange={setCalibration}
                  value={calibration}
                  h={"40px"}
                  width={"100%"}
                  type={"number"}
                  place={'mms/s'}
                />
              </TableCell>
              <TableCell
                align="center"
                sx={{ alignItems: "center", borderBottom: "none" }}
              >
                <p style={{ fontWeight: "bolder" }}>/</p>
              </TableCell>
              <TableCell
                align="start"
                sx={{ alignItems: "start", borderBottom: "none" }}
              >
                <TableField
                  handle={true}
                  range={{ min: 0, max: 180 }}
                  onChange={setCalibration2}
                  value={calibration2}
                  h={"40px"}
                  width={"100%"}
                  type={"number"}
                  place={'mm?mV'}
                />
              </TableCell>
            </TableRow>
          ) : null}

          {!show && hr2 ? (
            <TableRow
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell
                component="th"
                scope="row"
                sx={{
                  color: "#464646",
                  fontSize: "15px",
                  fontWeight: "bold",
                  borderBottom: "none",
                }}
              >
                HR
              </TableCell>
              <TableCell
                colSpan={3}
                align="start"
                sx={{ alignItems: "start", borderBottom: "none" }}
              >
                <p className="p_text">{hr2}</p>
              </TableCell>
            </TableRow>
          ) : null}

          {show ? (
            <TableRow
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell
                component="th"
                scope="row"
                sx={{
                  color: "#464646",
                  fontSize: "15px",
                  fontWeight: "bold",
                  borderBottom: "none",
                }}
              >
                HR
              </TableCell>
              <TableCell
                colSpan={3}
                align="start"
                sx={{ alignItems: "start", borderBottom: "none" }}
              >
                <TableField
                  handle={true}
                  onChange={setHr2}
                  value={hr2}
                  h={"40px"}
                  width={"100%"}
                  type={"number"}
                  range={{ min: 1, max: 170 }}
                />
              </TableCell>
            </TableRow>
          ) : null}

          {!show && bp12 && bp22 ? (
            <TableRow
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell
                component="th"
                scope="row"
                sx={{
                  color: "#464646",
                  fontSize: "15px",
                  fontWeight: "bold",
                  borderBottom: "none",
                }}
              >
                BP
              </TableCell>
              <TableCell
                align="start"
                sx={{ alignItems: "start", borderBottom: "none" , display:'flex',flexDirection:'row', gap:'20px'}}
              >
                <p className="p_text">{bp12}</p>
                <p style={{ fontWeight: "bolder" }}>/</p>
                <p className="p_text">{bp22}</p>
              </TableCell>
            
            </TableRow>
          ) : null}

          {show ? (
            <TableRow
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell
                component="th"
                scope="row"
                sx={{
                  color: "#464646",
                  fontSize: "15px",
                  fontWeight: "bold",
                  borderBottom: "none",
                }}
              >
                BP
              </TableCell>
              <TableCell
                align="start"
                sx={{ alignItems: "start", borderBottom: "none" }}
              >
                <TableField
                  handle={true}
                  range={{ min: 1, max: 250 }}
                  onChange={setBp12}
                  value={bp12}
                  h={"40px"}
                  width={"100%"}
                  type={"number"}
                />
              </TableCell>
              <TableCell
                align="center"
                sx={{ alignItems: "center", borderBottom: "none" }}
              >
                <p style={{ fontWeight: "bolder" }}>/</p>
              </TableCell>
              <TableCell
                align="start"
                sx={{ alignItems: "start", borderBottom: "none" }}
              >
                <TableField
                  handle={true}
                  range={{ min: 0, max: 180 }}
                  onChange={setBp22}
                  value={bp22}
                  h={"40px"}
                  width={"100%"}
                  type={"number"}
                />
              </TableCell>
            </TableRow>
          ) : null}

          {!show && spo22 ? (
            <TableRow
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell
                component="th"
                scope="row"
                sx={{
                  color: "#464646",
                  fontSize: "15px",
                  fontWeight: "bold",
                  borderBottom: "none",
                }}
              >
                SPO2
              </TableCell>
              <TableCell
                colSpan={3}
                align="start"
                sx={{ alignItems: "start", borderBottom: "none" }}
              >
                <p className="p_text">{spo22}</p>
              </TableCell>
            </TableRow>
          ) : null}
          {show ? (
            <TableRow
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell
                component="th"
                scope="row"
                sx={{
                  color: "#464646",
                  fontSize: "15px",
                  fontWeight: "bold",
                  borderBottom: "none",
                }}
              >
                SPO2
              </TableCell>
              <TableCell
                colSpan={3}
                align="start"
                sx={{ alignItems: "start", borderBottom: "none" }}
              >
                <TableField
                 handle={true}
                 range={{ min: 1, max: 100 }}
                  onChange={setSpo22}
                  value={spo22}
                  h={"40px"}
                  width={"100%"}
                  type={"number"}
                />
              </TableCell>
            </TableRow>
          ) : null}
          


          {!show && reason ? (
            <TableRow
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell
                component="th"
                scope="row"
                sx={{
                  color: "#464646",
                  fontSize: "15px",
                  fontWeight: "bold",
                  borderBottom: "none",
                }}
              >
               Reason for the ECG
              </TableCell>
              <TableCell
                colSpan={3}
                align="start"
                sx={{ alignItems: "start", borderBottom: "none" }}
              >
                <p className="p_text">{reason}</p>
              </TableCell>
            </TableRow>
          ) : null}
          {show ? (
            <TableRow
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell
                component="th"
                scope="row"
                sx={{
                  color: "#464646",
                  fontSize: "15px",
                  fontWeight: "bold",
                  borderBottom: "none",
                }}
              >
                Reason for the ECG
              </TableCell>
              <TableCell
                colSpan={3}
                align="start"
                sx={{ alignItems: "start", borderBottom: "none" }}
              >
                <TableField
                 handle={true}
                 range={{ min: 1, max: 100 }}
                  onChange={setReason}
                  value={reason}
                  h={"40px"}
                  width={"100%"}
                  type={"text"}
                />
              </TableCell>
            </TableRow>
          ) : null}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
