import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useTranslation } from "react-i18next";
import TableField from "./TableField";
import { Button, Stack } from "@mui/material";
import pic from "../Media/attachement.png";
import { useSelector } from "react-redux";
import FieldDisplay from "./FieldDisplay";

export default function FourthTable({
  d1,
  setD1,
  d2,
  setD2,
  d3,
  setD3,
  d4,
  setD4,
  d5,
  setD5,
  d6,
  setD6,
  d7,
  setD7,
  d8,
  setD8,
  d9,
  setD9,
  d10,
  setD10,
  d11,
  setD11,
  d12,
  setD12,
  d13,
  setD13,
  d14,
  setD14,
  d15,
  setD15,
  d16,
  setD16,
  d17,
  setD17,
  d18,
  setD18,
  d19,
  setD19,
  d20,
  setD20,
  d21,
  setD21,
  d22,
  setD22,
  d23,
  setD23,
  d24,
  setD24,
  d25,
  setD25,
  d26,
  setD26,
  d27,
  setD27,
  d28,
  setD28,
  d29,
  setD29,
  d30,
  setD30,
  show,
  status
}) {
  const { t } = useTranslation();
  const { type } = useSelector((state) => state.auth);

  return (
    <TableContainer
      component={Paper}
      sx={{ width: "100%", boxShadow: "none", direction: "ltr" }}
    >
      <Table
        sx={{
          border: "1.5px solid #008080",
          ".MuiTableCell-root": { fontFamily: '"Quicksand", sans-serif' },
        }}
      >
        <TableHead sx={{ bgcolor: "#008080" }}>
          <TableRow>
            <TableCell colSpan={10} sx={{ color: "white", fontSize: "15px" }}>
              {t("Summary and Conclusion")}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {(d1 || d2 || d3 || d4 || d5 || d6) && !show ? <><TableRow>
            <TableCell
              colSpan={1}
              component="th"
              scope="row"
              sx={{
                color: "#464646",
                fontSize: "15px",
                fontWeight: "bold",
                alignContent: "start",
              }}
            >
              <p>Left Ventricle :</p>
            </TableCell>

            <TableCell
              align="start"
              sx={{ alignItems: "start" }}
            >
              <Stack
                direction="row"
                gap={2}
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "start",
                  alignItems: "center",
                  'MuiStack-root':{
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "start",
                    alignItems: "center",
                  }
                }}
              >
               <FieldDisplay
                  value={d1}
                  show={show}
                />
               {d1 && <p
                  style={{
                    color: "#464646",
                    fontSize: "10px",
                    fontWeight: "bold",
                  }}
                >
                  Size and
                </p>}

                <FieldDisplay
                  value={d2}
                  show={show}
                />
                {d2 && <p
                  style={{
                    color: "#464646",
                    fontSize: "10px",
                    fontWeight: "bold",
                  }}
                >
                  Function and
                </p>}

                <FieldDisplay
                  value={d3}
                  show={show}
                />
               { d3 && <p
                  style={{
                    color: "#464646",
                    fontSize: "10px",
                    fontWeight: "bold",
                  }}
                >
                  LVH.
                </p>}

                {d4 &&  <FieldDisplay
                  value={d4}
                  show={show}
                />}
                {d5 &&  <FieldDisplay
                  value={d5}
                  show={show}
                />}

               {d6 &&   <FieldDisplay
                  value={d6}
                  show={show}
                />}
              </Stack>
            </TableCell>
          </TableRow> </> : null }

         {show && <TableRow>
            <TableCell
              colSpan={1}
              component="th"
              scope="row"
              sx={{
                color: "#464646",
                fontSize: "15px",
                fontWeight: "bold",
                alignContent: "start",
                alignContent: "start",
              }}
            >
              <p>Left Ventricle :</p>
            </TableCell>

            <TableCell
              align="start"
              sx={{ alignItems: "start", alignContent: "start" }}
            >
              <Stack
                direction="row"
                gap={2}
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "start",
                  alignItems: "center",
                }}
              >
                <FieldDisplay
                  value={d1}
                  setValue={setD1}
                  show={show}
                  data={[
                    { title: "-", id: 1 },
                    { title: "Normal", id: 2 },
                    { title: "Abnormal", id: 3 },
                    { title: "Mildly Enlarged", id: 4 },
                    { title: "Moderately Enlarged", id: 5 },
                    { title: "Severely Enlarged", id: 6 },
                    { title: "Small", id: 7 },
                  ]}
                />
                <p
                  style={{
                    color: "#464646",
                    fontSize: "10px",
                    fontWeight: "bold",
                  }}
                >
                  Size and
                </p>

                <FieldDisplay
                  value={d2}
                  setValue={setD2}
                  show={show}
                  data={[
                    { title: "-" },
                    { title: "Normal" },
                    { title: "Abnormal" },
                    { title: "Mildly Enlarged" },
                    { title: "Moderately Enlarged" },
                    { title: "Severely Enlarged" },
                    { title: "Small" },
                  ]}
                />
                <p
                  style={{
                    color: "#464646",
                    fontSize: "10px",
                    fontWeight: "bold",
                  }}
                >
                  Function and
                </p>

                <FieldDisplay
                  value={d3}
                  setValue={setD3}
                  show={show}
                  data={[
                    { title: "-" },
                    { title: "Normal" },
                    { title: "Preserved" },
                    { title: "Mild Reduced" },
                    { title: "Moderate Reduced" },
                    { title: "Severe Reduced" },
                    { title: "Hypercontractile" },
                  ]}
                />
                <p
                  style={{
                    color: "#464646",
                    fontSize: "10px",
                    fontWeight: "bold",
                  }}
                >
                  LVH.
                </p>

                <FieldDisplay
                  value={d4}
                  setValue={setD4}
                  show={show}
                  data={[
                    { title: "-" },
                    { title: "Moderate" },
                    { title: "Sever" },
                  ]}
                />
                <FieldDisplay
                  value={d5}
                  setValue={setD5}
                  show={show}
                  data={[
                    { title: "-" },
                    { title: "Anterior" },
                    { title: "Posterior" },
                    { title: "Inferior" },
                    { title: "Septal" },
                    { title: "Lateral" },
                    { title: "Apical" },
                    { title: "Basal" },
                  ]}
                />

                <FieldDisplay
                  value={d6}
                  setValue={setD6}
                  show={show}
                  data={[
                    { title: "-" },
                    { title: "Hypokinesia" },
                    { title: "Akinetic" },
                    { title: "Dyskinetic" },
                  ]}
                />
              </Stack>
            </TableCell>
          </TableRow> }









         {d7 && !show && <TableRow>
            <TableCell
              colSpan={1}
              component="th"
              scope="row"
              sx={{
                color: "#464646",
                fontSize: "15px",
                fontWeight: "bold",
                alignContent: "start",
              }}
            >
              Diastolic Function :
            </TableCell>

            <TableCell align="start" sx={{ alignItems: "start" }}>
              <Stack
                direction="row"
                gap={2}
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "start",
                  alignItems: "center",
                }}
              >
                 <FieldDisplay
                  value={d7}
                  show={show}
                />
                <p
                  style={{
                    color: "#464646",
                    fontSize: "10px",
                    fontWeight: "bold",
                  }}
                >
                  dysfunction.
                </p>
              </Stack>
            </TableCell>
          </TableRow>}

          {show && <TableRow>
            <TableCell
              colSpan={1}
              component="th"
              scope="row"
              sx={{
                color: "#464646",
                fontSize: "15px",
                fontWeight: "bold",
                alignContent: "start",
              }}
            >
              Diastolic Function :
            </TableCell>

            <TableCell align="start" sx={{ alignItems: "start" }}>
              <Stack
                direction="row"
                gap={2}
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "start",
                  alignItems: "center",
                }}
              >
                <FieldDisplay
                  value={d7}
                  setValue={setD7}
                  show={show}
                  data={[
                    { title: "-" },
                    { title: "Normal" },
                    { title: "Gride I" },
                    { title: "Gride II" },
                    { title: "Gride III" },
                  ]}
                />
                <p
                  style={{
                    color: "#464646",
                    fontSize: "10px",
                    fontWeight: "bold",
                  }}
                >
                  dysfunction.
                </p>
              </Stack>
            </TableCell>
          </TableRow>}





         {(d8 || d9) && !show && <TableRow>
            <TableCell
              colSpan={1}
              component="th"
              scope="row"
              sx={{
                color: "#464646",
                fontSize: "15px",
                fontWeight: "bold",
                alignContent: "start",
              }}
            >
              Right Ventricle :
            </TableCell>

            <TableCell align="start" sx={{ alignItems: "start" }}>
              <Stack
                direction="row"
                gap={2}
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "start",
                  alignItems: "center",
                }}
              >
                <FieldDisplay
                  value={d8}
                  show={show}
                />

               {d8 && <p
                  style={{
                    color: "#464646",
                    fontSize: "10px",
                    fontWeight: "bold",
                  }}
                >
                  Size and
                </p>}

                <FieldDisplay
                  value={d9}
                  show={show}
                />
               {d9 && <p
                  style={{
                    color: "#464646",
                    fontSize: "10px",
                    fontWeight: "bold",
                  }}
                >
                  Function.
                </p>}
              </Stack>
            </TableCell>
          </TableRow>}

         {show && <TableRow>
            <TableCell
              colSpan={1}
              component="th"
              scope="row"
              sx={{
                color: "#464646",
                fontSize: "15px",
                fontWeight: "bold",
                alignContent: "start",
              }}
            >
              Right Ventricle :
            </TableCell>

            <TableCell align="start" sx={{ alignItems: "start" }}>
              <Stack
                direction="row"
                gap={2}
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "start",
                  alignItems: "center",
                }}
              >
                <FieldDisplay
                  value={d8}
                  setValue={setD8}
                  show={show}
                  data={[
                    { title: "-" },
                    { title: "Normal" },
                    { title: "Abnormal" },
                    { title: "Mildly Enlarged" },
                    { title: "Moderately Enlarged" },
                    { title: "Severely Enlarged" },
                    { title: "Small" },
                  ]}
                />

                <p
                  style={{
                    color: "#464646",
                    fontSize: "10px",
                    fontWeight: "bold",
                  }}
                >
                  Size and
                </p>

                <FieldDisplay
                  value={d9}
                  setValue={setD9}
                  show={show}
                  data={[
                    { title: "-" },
                    { title: "Normal" },
                    { title: "Abnormal" },
                    { title: "Mildly Enlarged" },
                    { title: "Moderately Enlarged" },
                    { title: "Severely Enlarged" },
                    { title: "Small" },
                  ]}
                />
                <p
                  style={{
                    color: "#464646",
                    fontSize: "10px",
                    fontWeight: "bold",
                  }}
                >
                  Function.
                </p>
              </Stack>
            </TableCell>
          </TableRow>}




          {d10 && !show && <TableRow>
            <TableCell
              colSpan={1}
              component="th"
              scope="row"
              sx={{
                color: "#464646",
                fontSize: "15px",
                fontWeight: "bold",
                alignContent: "start",
              }}
            >
              Left Atrium :
            </TableCell>

            <TableCell align="start" sx={{ alignItems: "start" }}>
              <Stack
                direction="row"
                gap={2}
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "start",
                  alignItems: "center",
                }}
              >
                <FieldDisplay
                  value={d10}
                  show={show}
                />
                <p
                  style={{
                    color: "#464646",
                    fontSize: "10px",
                    fontWeight: "bold",
                  }}
                >
                  Size.
                </p>
              </Stack>
            </TableCell>
          </TableRow>}



          {show && <TableRow>
            <TableCell
              colSpan={1}
              component="th"
              scope="row"
              sx={{
                color: "#464646",
                fontSize: "15px",
                fontWeight: "bold",
                alignContent: "start",
              }}
            >
              Left Atrium :
            </TableCell>

            <TableCell align="start" sx={{ alignItems: "start" }}>
              <Stack
                direction="row"
                gap={2}
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "start",
                  alignItems: "center",
                }}
              >
                <FieldDisplay
                  value={d10}
                  setValue={setD10}
                  show={show}
                  data={[
                    { title: "-" },
                    { title: "Normal" },
                    { title: "Abnormal" },
                    { title: "Mildly Enlarged" },
                    { title: "Moderately Enlarged" },
                    { title: "Severely Enlarged" },
                    { title: "Small" },
                  ]}
                />
                <p
                  style={{
                    color: "#464646",
                    fontSize: "10px",
                    fontWeight: "bold",
                  }}
                >
                  Size.
                </p>
              </Stack>
            </TableCell>
          </TableRow>}

          {(d11 || d12 || d13 || d14 || d15 )&& !show && <TableRow>
            <TableCell
              colSpan={1}
              component="th"
              scope="row"
              sx={{
                color: "#464646",
                fontSize: "15px",
                fontWeight: "bold",
                alignContent: "start",
              }}
            >
              Mitral Valve :
            </TableCell>

            <TableCell align="start" sx={{ alignItems: "start" }}>
              <Stack
                direction="row"
                gap={2}
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "start",
                  alignItems: "center",
                }}
              >
                <FieldDisplay
                  value={d11}
                  show={show}
                />
              {d11 &&  <p
                  style={{
                    color: "#464646",
                    fontSize: "10px",
                    fontWeight: "bold",
                  }}
                >
                  Valve with
                </p>}

                <FieldDisplay
                  value={d12}
                  show={show}
                />

              { d12 && <p
                  style={{
                    color: "#464646",
                    fontSize: "10px",
                    fontWeight: "bold",
                  }}
                >
                  leaflet.
                </p>}

                <FieldDisplay
                  value={d13}
                  show={show}
                />

               {d13 && <p
                  style={{
                    color: "#464646",
                    fontSize: "10px",
                    fontWeight: "bold",
                  }}
                >
                  Regurgitation and
                </p>}

                <FieldDisplay
                  value={d14}
                  show={show}
                />

               {d14 && <p
                  style={{
                    color: "#464646",
                    fontSize: "10px",
                    fontWeight: "bold",
                  }}
                >
                  Stenosis
                </p>}
                <FieldDisplay
                  value={d15}
                  show={show}
                  type="text"
                />
              </Stack>
            </TableCell>
          </TableRow>}

         { show && <TableRow>
            <TableCell
              colSpan={1}
              component="th"
              scope="row"
              sx={{
                color: "#464646",
                fontSize: "15px",
                fontWeight: "bold",
                alignContent: "start",
              }}
            >
              Mitral Valve :
            </TableCell>

            <TableCell align="start" sx={{ alignItems: "start" }}>
              <Stack
                direction="row"
                gap={2}
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "start",
                  alignItems: "center",
                }}
              >
                <FieldDisplay
                  value={d11}
                  setValue={setD11}
                  show={show}
                  data={[
                    { title: "-" },
                    { title: "Mechanical" },
                    { title: "Bioprosthetic" },
                  ]}
                />
                <p
                  style={{
                    color: "#464646",
                    fontSize: "10px",
                    fontWeight: "bold",
                  }}
                >
                  Valve with
                </p>

                <FieldDisplay
                  value={d12}
                  setValue={setD12}
                  show={show}
                  data={[
                    { title: "-" },
                    { title: "Normal" },
                    { title: "Calcified" },
                    { title: "Thainkend" },
                    { title: "Mass" },
                  ]}
                />

                <p
                  style={{
                    color: "#464646",
                    fontSize: "10px",
                    fontWeight: "bold",
                  }}
                >
                  leaflet.
                </p>

                <FieldDisplay
                  value={d13}
                  setValue={setD13}
                  show={show}
                  data={[
                    { title: "-" },
                    { title: "None" },
                    { title: "Trace" },
                    { title: "Mild" },
                    { title: "Moderate" },
                    { title: "Severe" },
                  ]}
                />

                <p
                  style={{
                    color: "#464646",
                    fontSize: "10px",
                    fontWeight: "bold",
                  }}
                >
                  Regurgitation and
                </p>

                <FieldDisplay
                  value={d14}
                  setValue={setD14}
                  show={show}
                  data={[
                    { title: "-" },
                    { title: "None" },
                    { title: "Trace" },
                    { title: "Mild" },
                    { title: "Moderate" },
                    { title: "Severe" },
                  ]}
                />

                <p
                  style={{
                    color: "#464646",
                    fontSize: "10px",
                    fontWeight: "bold",
                  }}
                >
                  Stenosis
                </p>
                <FieldDisplay
                  value={d15}
                  setValue={setD15}
                  show={show}
                  type="text"
                />
              </Stack>
            </TableCell>
          </TableRow>}





          {(d16||d17||d18||d19||d20||d21) && !show && <TableRow>
            <TableCell
              colSpan={1}
              component="th"
              scope="row"
              sx={{
                color: "#464646",
                fontSize: "15px",
                fontWeight: "bold",
                alignContent: "start",
              }}
            >
              Aortic Valve is :
            </TableCell>

            <TableCell align="start" sx={{ alignItems: "start" }}>
              <Stack
                direction="row"
                gap={2}
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "start",
                  alignItems: "center",
                }}
              >
                <FieldDisplay
                  value={d16}
                  show={show} 
                />
                <FieldDisplay
                  value={d17}
                  show={show}
                />
                {d17 && <p
                  style={{
                    color: "#464646",
                    fontSize: "10px",
                    fontWeight: "bold",
                  }}
                >
                  and
                </p>}

                <FieldDisplay
                  value={d18}
                  show={show}
                 
                />

              {d18 &&  <p
                  style={{
                    color: "#464646",
                    fontSize: "10px",
                    fontWeight: "bold",
                  }}
                >
                  and
                </p>}
                <FieldDisplay
                  value={d19}
                  show={show}
                />
                {d19 && <p
                  style={{
                    color: "#464646",
                    fontSize: "10px",
                    fontWeight: "bold",
                  }}
                >
                  Regurgitation and
                </p>}
                <FieldDisplay
                  value={d20}
                  show={show} 
                />
               {d20 && <p
                  style={{
                    color: "#464646",
                    fontSize: "10px",
                    fontWeight: "bold",
                  }}
                >
                  Senosis
                </p>}
              
                  <FieldDisplay
                  value={d21}
                  show={show}
                  type="text"
                />
              </Stack>
            </TableCell>
          </TableRow>}


          {show && <TableRow>
            <TableCell
              colSpan={1}
              component="th"
              scope="row"
              sx={{
                color: "#464646",
                fontSize: "15px",
                fontWeight: "bold",
                alignContent: "start",
              }}
            >
              Aortic Valve is :
            </TableCell>

            <TableCell align="start" sx={{ alignItems: "start" }}>
              <Stack
                direction="row"
                gap={2}
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "start",
                  alignItems: "center",
                }}
              >
                <FieldDisplay
                  value={d16}
                  setValue={setD16}
                  show={show}
                  data={[
                    { title: "-" },
                    { title: "Normal" },
                    { title: "Calcified" },
                    { title: "Sclerotic" },
                    { title: "Misshapen" },
                    { title: "Bioprosthetic" },
                    { title: "Mechanical prosthetic" },
                  ]}
                />
                <FieldDisplay
                  value={d17}
                  setValue={setD17}
                  show={show}
                  data={[
                    { title: "-" },
                    { title: "Tricuspid" },
                    { title: "Bicuspid" },
                    { title: "Intact" },
                  ]}
                />

                <p
                  style={{
                    color: "#464646",
                    fontSize: "10px",
                    fontWeight: "bold",
                  }}
                >
                  and
                </p>

                <FieldDisplay
                  value={d18}
                  setValue={setD18}
                  show={show}
                  data={[
                    { title: "-" },
                    { title: "Normal Mobility" },
                    { title: "Diminished Mobility" },
                    { title: "Severely diminished mobility" },
                    { title: "Fine fluttering of the aortic cups" },
                    { title: "Apparently abnormal mobility" },
                    { title: "No vegetations are seen" },
                    { title: "Possible vegetations are seen" },
                  ]}
                />

                <p
                  style={{
                    color: "#464646",
                    fontSize: "10px",
                    fontWeight: "bold",
                  }}
                >
                  and
                </p>
                <FieldDisplay
                  value={d19}
                  setValue={setD19}
                  show={show}
                  data={[
                    { title: "-" },
                    { title: "None" },
                    { title: "Trace(trivial)" },
                    { title: "Mild" },
                    { title: "Mild to moderate" },
                    { title: "Moderate" },
                    { title: "Moderate to Severe" },
                  ]}
                />
                <p
                  style={{
                    color: "#464646",
                    fontSize: "10px",
                    fontWeight: "bold",
                  }}
                >
                  Regurgitation and
                </p>
                <FieldDisplay
                  value={d20}
                  setValue={setD20}
                  show={show}
                  data={[
                    { title: "-" },
                    { title: "None" },
                    { title: "Mild" },
                    { title: "Mild to moderate" },
                    { title: "Moderate" },
                    { title: "Moderate to Severe" },
                    { title: "Severe" },
                  ]}
                />
                <p
                  style={{
                    color: "#464646",
                    fontSize: "10px",
                    fontWeight: "bold",
                  }}
                >
                  Senosis
                </p>
              
                  <FieldDisplay
                  value={d21}
                  setValue={setD21}
                  show={show}
                  type="text"
                />
              </Stack>
            </TableCell>
          </TableRow>}





         {(d21 || d22 || d23 || d24 || d25 || d26) && !show && <TableRow>
            <TableCell
              colSpan={1}
              component="th"
              scope="row"
              sx={{
                color: "#464646",
                fontSize: "15px",
                fontWeight: "bold",
                alignContent: "start",
              }}
            >
              Tricuspid Valve is :
            </TableCell>

            <TableCell align="start" sx={{ alignItems: "start" }}>
              <Stack
                direction="row"
                gap={2}
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "start",
                  alignItems: "center",
                }}
              >               
                 <FieldDisplay
                  value={d22}
                  show={show}
                  
                />
               {d22 && <p
                  style={{
                    color: "#464646",
                    fontSize: "10px",
                    fontWeight: "bold",
                  }}
                >
                  and
                </p>}
                 <FieldDisplay
                  value={d23}
                  show={show}
                />

               {d23 && <p
                  style={{
                    color: "#464646",
                    fontSize: "10px",
                    fontWeight: "bold",
                  }}
                >
                  Regurgitation and
                </p>}
                  <FieldDisplay
                  value={d24}
                  show={show}
                
                />

              {d24 &&  <p
                  style={{
                    color: "#464646",
                    fontSize: "10px",
                    fontWeight: "bold",
                  }}
                >
                  Senosis and
                </p>} 
            
                 <FieldDisplay
                  value={d25}
                  show={show}
                />

                {d26 && <p
                  style={{
                    color: "#464646",
                    fontSize: "10px",
                    fontWeight: "bold",
                  }}
                >
                  Pulmonary hypertension (TIG)
                </p>}
              
                   <FieldDisplay
                  value={d26}
                  show={show}
                />
              </Stack>
            </TableCell>
          </TableRow>}

        {show &&  <TableRow>
            <TableCell
              colSpan={1}
              component="th"
              scope="row"
              sx={{
                color: "#464646",
                fontSize: "15px",
                fontWeight: "bold",
                alignContent: "start",
              }}
            >
              Tricuspid Valve is :
            </TableCell>

            <TableCell align="start" sx={{ alignItems: "start" }}>
              <Stack
                direction="row"
                gap={2}
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "start",
                  alignItems: "center",
                }}
              >               
                 <FieldDisplay
                  value={d22}
                  setValue={setD22}
                  show={show}
                  data={[
                    { title: "-" },
                    { title: "Normal" },
                    { title: "Abnormal" },
                    { title: "Rheumatic" },
                    { title: "Vegetation" },
                  ]}
                />
                <p
                  style={{
                    color: "#464646",
                    fontSize: "10px",
                    fontWeight: "bold",
                  }}
                >
                  and
                </p>
                 <FieldDisplay
                  value={d23}
                  setValue={setD23}
                  show={show}
                  data={[
                    { title: "-" },
                    { title: "None" },
                    { title: "Trace(trivial)" },
                    { title: "Mild" },
                    { title: "Mild to moderate" },
                    { title: "Moderate" },
                    { title: "Moderate to Severe" },
                    { title: "Severe" },
                  ]}
                />

                <p
                  style={{
                    color: "#464646",
                    fontSize: "10px",
                    fontWeight: "bold",
                  }}
                >
                  Regurgitation and
                </p>
                  <FieldDisplay
                  value={d24}
                  setValue={setD24}
                  show={show}
                  data={[
                    { title: "-" },
                    { title: "None" },
                    { title: "Mild" },
                    { title: "Mild to Moderate" },
                    { title: "Moderate" },
                    { title: "Moderate to Severe" },
                    { title: "Severe" },
                  ]}
                />

                <p
                  style={{
                    color: "#464646",
                    fontSize: "10px",
                    fontWeight: "bold",
                  }}
                >
                  Senosis
                </p>
                <p
                  style={{
                    color: "#464646",
                    fontSize: "10px",
                    fontWeight: "bold",
                  }}
                >
                  and
                </p>
            
                 <FieldDisplay
                  value={d25}
                  setValue={setD25}
                  show={show}
                  type="number"
                />

                <p
                  style={{
                    color: "#464646",
                    fontSize: "10px",
                    fontWeight: "bold",
                  }}
                >
                  Pulmonary hypertension (TIG)
                </p>
              
                   <FieldDisplay
                  value={d26}
                  setValue={setD26}
                  show={show}
                  type="text"
                />
              </Stack>
            </TableCell>
          </TableRow>}




         {d27 && !show && <TableRow>
            <TableCell
              colSpan={1}
              component="th"
              scope="row"
              sx={{
                color: "#464646",
                fontSize: "15px",
                fontWeight: "bold",
                alignContent: "start",
              }}
            >
              Pulmonary Valve :
            </TableCell>

            <TableCell align="start" sx={{ alignItems: "start" }}>
                   <FieldDisplay
                  value={d27}
                  show={show}
                />
            </TableCell>
          </TableRow>}

          {show &&  <TableRow>
            <TableCell
              colSpan={1}
              component="th"
              scope="row"
              sx={{
                color: "#464646",
                fontSize: "15px",
                fontWeight: "bold",
                alignContent: "start",
              }}
            >
              Pulmonary Valve :
            </TableCell>

            <TableCell align="start" sx={{ alignItems: "start" }}>
                   <FieldDisplay
                  value={d27}
                  setValue={setD27}
                  show={show}
                  data={[
                    { title: "-" },
                    { title: "Normal" },
                    { title: "Abnormal" },
                    { title: "Doming" },
                    { title: "Dilated annulus" },
                  ]}
                />
            </TableCell>
          </TableRow>}



         {d28 && !show && <TableRow>
            <TableCell
              colSpan={1}
              component="th"
              scope="row"
              sx={{
                color: "#464646",
                fontSize: "15px",
                fontWeight: "bold",
                alignContent: "start",
              }}
            >
              There is :
            </TableCell>

            <TableCell align="start" sx={{ alignItems: "start" }}>
              <Stack
                direction="row"
                gap={2}
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "start",
                  alignItems: "center",
                }}
              >
                   <FieldDisplay
                  value={d28}
                  show={show}
                 
                />
                <p
                  style={{
                    color: "#464646",
                    fontSize: "10px",
                    fontWeight: "bold",
                  }}
                >
                  pericardial effusion
                </p>
              </Stack>
            </TableCell>
          </TableRow>}


         {show && <TableRow>
            <TableCell
              colSpan={1}
              component="th"
              scope="row"
              sx={{
                color: "#464646",
                fontSize: "15px",
                fontWeight: "bold",
                alignContent: "start",
              }}
            >
              There is :
            </TableCell>

            <TableCell align="start" sx={{ alignItems: "start" }}>
              <Stack
                direction="row"
                gap={2}
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "start",
                  alignItems: "center",
                }}
              >
                   <FieldDisplay
                  value={d28}
                  setValue={setD28}
                  show={show}
                  data={[
                    { title: "-" },
                    { title: "No" },
                    { title: "Mild" },
                    { title: "Moderate" },
                    { title: "Severe" },
                  ]}
                />
                <p
                  style={{
                    color: "#464646",
                    fontSize: "10px",
                    fontWeight: "bold",
                  }}
                >
                  pericardial effusion
                </p>
              </Stack>
            </TableCell>
          </TableRow>}


         {d29 && !show && <TableRow>
            <TableCell
              colSpan={1}
              component="th"
              scope="row"
              sx={{
                color: "#464646",
                fontSize: "15px",
                fontWeight: "bold",
                alignContent: "start",
              }}
            >
              Extra Notes :
            </TableCell>

            <TableCell align="start" sx={{ alignItems: "start" }}>
                  <FieldDisplay
                  value={d29}
                  setValue={setD29}
                  show={show}
                  type="text"
                />
            </TableCell>
          </TableRow>}
          {show && <TableRow>
            <TableCell
              colSpan={1}
              component="th"
              scope="row"
              sx={{
                color: "#464646",
                fontSize: "15px",
                fontWeight: "bold",
                alignContent: "start",
              }}
            >
              Extra Notes :
            </TableCell>

            <TableCell align="start" sx={{ alignItems: "start" }}>
                  <FieldDisplay
                  value={d29}
                  setValue={setD29}
                  show={show}
                  type="text"
                />
            </TableCell>
          </TableRow>}

          {type === "technician" && status ==='Filling Form'  ? (
            <TableRow>
              <TableCell
                colSpan={1}
                component="th"
                scope="row"
                sx={{
                  color: "#464646",
                  fontSize: "15px",
                  fontWeight: "bold",
                  alignContent: "start",
                }}
              >
                ECHO Attachements :
              </TableCell>

              <TableCell align="start" sx={{ alignItems: "start" }}>
                <Stack
                  direction="row"
                  gap={2}
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "start",
                    alignItems: "center",
                  }}
                >
                    <>
                      {show ? (
                        <TableField
                          onChange={setD30}
                          value={d30}
                          h={"30px"}
                          width={"500px"}
                          type={"file"}
                          zipFile={true}
                        />
                      ) : null }
                    </>
                 
          
                 
                    <img
                      src={pic}
                      style={{
                        width: "20px",
                        height: "20px",
                        marginTop: "5px",
                      }}
                    />
                
                </Stack>
              </TableCell>
            </TableRow>
          ) : null}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
