import React, { useEffect, useState } from "react";
import pic from "../Media/privacy.png";
import { Container, Typography, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import pic1 from "../Media/Vector2.png";
import pic2 from "../Media/Group.png";
import LoadingPage from "../Components/LoadingPage";
import axios from "axios";
import ContactIcon from "../Components/ContactIcon";
import Booking from "../Components/Booking";

export default function PrivacyPolice() {
  const matches = useMediaQuery("(min-width:700px)");
  const windowHeight = React.useRef(window.innerHeight);
  const { t } = useTranslation();
  const [open, setOpen] = useState(true);
  const [lang, setLang] = useState(localStorage.getItem("lang"));
  const [privacy,setPrivacy] = useState()

  useEffect(() => {
    window.scrollTo(0, 0);
    axios.get(`${process.env.REACT_APP_API_URL}privacy`, {
      headers: {
        "Accept-Language": lang,
      },
    }).then(
      res=>{
        setPrivacy(res.data.data.privacy)
        setOpen(false)
      }
    )
  }, []);

  return (
    <div>
            {open && <LoadingPage open={open}/>}
      {!open && <><div
        style={{
          position: "relative",
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          alignItems: "center",
          height: matches ? windowHeight.current : "300px",
        }}
      >
        <img
          src={pic}
          style={{
            height: matches ? windowHeight.current : "300px",
            objectFit: "cover",
            width: "100%",
          }}
        />
        <div className="overLay"></div>
        <p className="sections-text">{t("Privacy Policy")}</p>
      </div>

      <div style={{ position: "relative" }}>
        <img
          src={pic1}
          style={{
            position: "absolute",
            top: matches ? "-50px" : "-70px",
            width: "100px",
            left: "40px",
          }}
        />

    {   matches &&  <img
          src={pic2}
          style={{
            position: "absolute",
            top: matches ? "80%" : "90%",
            width: "100px",
            right: "40px",
          }}
        />}
<Booking />
        <Container maxWidth="md" sx={{ pb: "100px", mt: "100px" }}>
          <p sx={{ color: "#6D6D6D" }}  dangerouslySetInnerHTML={{ __html: privacy }}>
          </p>
        </Container>
      </div>
      </>}
      <ContactIcon />
    </div>
  );
}
