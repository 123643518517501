import React, { useEffect, useState } from "react";
import Introduction from "../Components/Introduction";
import Services from "../Components/Services";
import Technologies from "../Components/Technologies";
import Story from "../Components/Story";
import Faqs from "../Components/Faqs";
import WhyUs from "../Components/WhyUs";
import Support from "../Components/Support";
import LoadingPage from "../Components/LoadingPage";
import axios from "axios";
import ContactIcon from "../Components/ContactIcon";
import Partner from "../Components/Partner";
import Booking from "../Components/Booking";

export default function MainPage() {
  const [open, setOpen] = useState(true);
  const [lang, setLang] = useState(localStorage.getItem("lang") || "en"); 
  const [slider, setSlider] = useState([]);
  const [services, setServices] = useState([]);
  const [techs, setTechs] = useState([]);
  const [whyUs, setWhyUs] = useState([]);
  const [faq, setFaq] = useState([]);
  const [story, setStory] = useState();
  const [serviceDescription, setServiceDescription] = useState("");
  const [technologyDescription, setTechnologyDescription] = useState("");
  const [featureDescription, setFeatureDescription] = useState("");
  const [faqDescription, setFaqDescription] = useState("");
  const [partnerDescription, setPartnerDescription] = useState("");
  const [contactDescription, setContactDescription] = useState("");

  useEffect(() => {
    
    window.scrollTo(0, 0);
    axios
      .get(`${process.env.REACT_APP_API_URL}home`, {
        headers: {
          "Accept-Language": lang,
        },
      })
      .then((res) => {
        setSlider(res.data.data.sliders);
        setServices(res.data.data.services);
        setTechs(res.data.data.technologies);
        setWhyUs(res.data.data.why_choose_us);
        setFaq(res.data.data.faqs);
        setStory(res.data.data.story);
        for (var i = 0; i < res.data.data.section_headers.length; i++) {
          if (res.data.data.section_headers[i].key === "service") {
            setServiceDescription(res.data.data.section_headers[i].description);
          } else if (res.data.data.section_headers[i].key === "technology") {
            setTechnologyDescription(
              res.data.data.section_headers[i].description
            );
          } else if (res.data.data.section_headers[i].key === "feature") {
            setFeatureDescription(res.data.data.section_headers[i].description);
          } else if (res.data.data.section_headers[i].key === "faq") {
            setFaqDescription(res.data.data.section_headers[i].description);
          } else if (res.data.data.section_headers[i].key === "partner") {
            setPartnerDescription(res.data.data.section_headers[i].description);
          } else if (res.data.data.section_headers[i].key === "contact") {
            setContactDescription(res.data.data.section_headers[i].description);
          }
        }
        setOpen(false);
        localStorage.setItem("number", res.data.data.general.whatsapp);
      });
  }, []);

  return (
    <div style={{ position: "relative", paddingBottom: "100px",marginTop:'100px' }}>
      {open && <LoadingPage open={open} />}
      {!open && (
        <>
          <Introduction slider={slider} />
          <Services
            services={services}
            serviceDescription={serviceDescription}
          />
          <Booking />
          <Technologies
            techs={techs}
            technologyDescription={technologyDescription}
          />
          <WhyUs whyUs={whyUs} featureDescription={featureDescription} />
          <Story story={story} />
          <Faqs faq={faq} faqDescription={faqDescription} />
          <Partner partnerDescription={partnerDescription} />
          <Support contactDescription={contactDescription} />
          <ContactIcon />
        </>
      )}
    </div>
  );
}
