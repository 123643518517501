import { TextField, MenuItem, ThemeProvider, useTheme, IconButton, Modal } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { useState } from "react";
import { Close as CloseIcon, ZoomIn as ZoomInIcon } from '@mui/icons-material';

export default function TableField({
  value, 
  onChange,
  width,
  h,
  type,
  data,
  place,
  handle,
  range,
  allowMultiple, 
  zipFile
}) {
  const { min, max } = range || {}; 
  const outerTheme = useTheme();
  const [error, setError] = useState(false);
  const [selectedImages, setSelectedImages] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [currentImage, setCurrentImage] = useState(null);

  const handleChange = (e) => {
    if (type === "file") {
      const files = Array.from(e.target.files); 

      if (zipFile) {
        const fileExtensions = files.map((file) => file.name.split(".").pop());
        const invalidFiles = fileExtensions.some(ext => !["zip", "rar"].includes(ext));

        if (invalidFiles) {
          setError(true);
          return;
        }
        setError(false);
        onChange(files); 
      } else {
        setError(false);
        const imageFiles = files.filter((file) => file.type.startsWith("image/"));
        setSelectedImages(imageFiles);
        onChange(imageFiles); 
      }
    } else {
      let newValue = e.target.value;
      if (type === "number") {
        newValue = parseFloat(newValue);
      }
      if (handle && (newValue < min || newValue > max)) {
        setError(true);
      } else {
        setError(false);
        onChange(newValue);
      }
    }
  };

  const handleRemoveImage = (image) => {
    const updatedImages = selectedImages.filter((img) => img !== image);
    setSelectedImages(updatedImages);
    onChange(updatedImages); 
  };

  const handleZoomIn = (image) => {
    setCurrentImage(image);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setCurrentImage(null);
  };

  const customTheme = (outerTheme) =>
    createTheme({
      palette: {
        mode: outerTheme.palette.mode,
      },
      components: {
        MuiInputLabel: {
          styleOverrides: {
            root: {
              color: "gray",
              height: h,
            },
          },
        },
        MuiOutlinedInput: {
          styleOverrides: {
            root: {
              "& .MuiOutlinedInput-notchedOutline": {
                border: error ? "2px solid red" : "1px solid #64C6C2",
                height: h,
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                border: error ? "2px solid red" : "1px solid #64C6C2",
                height: h,
              },
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                border: error ? "2px solid red" : "1px solid #64C6C2",
                height: h,
              },
            },
          },
        },
      },
    });

  return (
    <div style={{ marginTop: "10px" }}>
      <ThemeProvider theme={customTheme(outerTheme)}>
        <TextField
          error={error}
          helperText={error && "The value is not allowed"}
          placeholder={place || ""}
          type={type}
          value={value? value : ''}
          select={data ? true : false}
          onChange={handleChange}
          inputProps={{
            multiple: allowMultiple ? true : false,
            accept: zipFile ? ".zip,.rar" : "image/*",
          }}
          sx={{
            backgroundColor: "white",
            width: width,
            height: h,
            "& input[type='file']": {
              position: "absolute",
              top: "-13px",
            },
            ".MuiInputBase-root": {
              height: h,
            },
            ".MuiSelect-select":{
                color: "#464646",
                fontSize: "10px",
                fontWeight: "bold",
              }
          }}
        >
          {data &&
            data.map((s) => (
              <MenuItem value={s.title} key={s.title}>
                {s.title}
              </MenuItem>
            ))}
        </TextField>
      </ThemeProvider>


      <div className="selected-images-container" style={{ marginTop: '10px', display: 'flex', gap: '10px' }}>
        {selectedImages.map((image, index) => (
          <div key={index} style={{ position: 'relative' }}>
            <img
              src={URL.createObjectURL(image)}
              alt={`selected-${index}`}
              style={{ width: '100px', height: '100px', objectFit: 'cover', cursor: 'pointer' }}
              onClick={() => handleZoomIn(image)}
            />
            <IconButton
              style={{ position: 'absolute', top: 0, right: 0, background: 'rgba(255,255,255,0.8)' }}
              size="small"
              onClick={() => handleRemoveImage(image)}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </div>
        ))}
      </div>


      <Modal open={openModal} onClose={handleCloseModal} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <div style={{ outline: 'none', position: 'relative' }}>
          {currentImage && (
            <>
              <img
                src={URL.createObjectURL(currentImage)}
                alt="Zoomed"
                style={{ maxHeight: '90vh', maxWidth: '90vw', objectFit: 'contain' }}
              />
              <IconButton
                style={{ position: 'absolute', top: '10px', right: '10px', background: 'rgba(255,255,255,0.8)' }}
                onClick={handleCloseModal}
              >
                <CloseIcon />
              </IconButton>
            </>
          )}
        </div>
      </Modal>
    </div>
  );
}
