import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export default function Booking() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <div>
      <a
        style={{
          position: 'fixed',
          cursor: 'pointer',
          top: '60%',
          backgroundColor: '#0854C1',
          left: '0px',
          zIndex: 100,
          width: '35px',
          height: '200px',
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundImage: 'linear-gradient(to right, #64C6C6 , #008080)',
          color: 'white',
        }}
        onClick={() => {
          navigate('/requestservice');
        }}
      >
        <p
          style={{
            transform: 'rotate(-90deg)',
            color: 'white',
            whiteSpace: 'nowrap', 
            margin: 0,
          }}
        >
          {t('Book an appointment')}
        </p>
      </a>
    </div>
  );
}
