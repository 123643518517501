import React, { useEffect, useState } from "react";
import ButtonBar from "../Components/ButtonBar";
import { useSelector } from "react-redux";
import PatientDetails from "../Components/PatientDetails";
import GeneralTable from "../Components/GeneralTable";
import {
  Alert,
  Backdrop,
  Box,
  Button,
  Container,
  Stack,
  useMediaQuery,
} from "@mui/material";
import SecondTable from "../Components/SecondTable";
import ThiredTable from "../Components/ThiredTable";
import FourthTable from "../Components/FourthTable";
import EchoDetails from "../Components/EchoDetails";
import { useTranslation } from "react-i18next";
import AttachementTable from "../Components/AttachementTable";
import { useLocation, useNavigate } from "react-router-dom";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { styled } from "@mui/material/styles";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import axios from "axios";
import LoadingPage from "../Components/LoadingPage";
import Success from "../Components/Success";
import ContactIcon from "../Components/ContactIcon";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import PDFContent from "../Components/PDFContent ";
import { createRoot } from "react-dom/client";
import SecondGeneralTable from "../Components/SecondGeneralTable";
import ECGTable from "../Components/ECGTable";
import SecondSummeryTable from "../Components/SecondSummeryTable";
import SecondAttachTable from "../Components/SecondAttachTable";
import SecondReportPdf from "../Components/SecondReportPdf";

export default function Report() {
  const { type } = useSelector((state) => state.auth);

  const location = useLocation();
  if (type === "technician" || type === "supervisor" || type === "doctor") {
    const { state } = location;
    var { data } = state;
    localStorage.setItem("patient_id", data);
  }

  const navigate = useNavigate();
  const [expandedIndex, setExpandedIndex] = useState(null);
  const [expanding, setExpanding] = useState(false)
  const { t } = useTranslation();
  const windowHeight = React.useRef(window.innerHeight);
  const matches = useMediaQuery("(min-width:840px)");

  const [show, setShow] = React.useState(false);
  const [showSubmit, setShowSubmit] = React.useState(false);
  const [showFirstReport , setShowFirstReport] = useState(true)
  const [open, setOpen] = useState(true);
  const [details, setDetails] = useState([{}]);
  const [requests, setRequests] = useState([]);
  const [alert, setAlert] = React.useState(false);
  //   ----------------first table data
  const [physician, setPhysician] = React.useState("");
  const [indication, setIndication] = React.useState("");
  const [hr, setHr] = React.useState("");
  const [bp1, setBp1] = React.useState("");
  const [bp2, setBp2] = React.useState("");
  const [spo2, setSpo2] = React.useState("");

  //   ----------------second table data
  const [root, setRoot] = React.useState("");
  const [ascending, setAscending] = React.useState("");
  const [arch, setArch] = React.useState("");
  const [IVC, setIVC] = React.useState("");

  const [EDd, setEDd] = React.useState("");
  const [LVPWD, setLVPWD] = React.useState("");
  const [EDs, setEDs] = React.useState("");
  const [LVPWS, setLVPWS] = React.useState("");
  const [IVSDd, setIVSDd] = React.useState("");
  const [EWave, setEWave] = React.useState("");
  const [IVSSd, setIVSSd] = React.useState("");
  const [AWave, setAWave] = React.useState("");
  const [EF, setEF] = React.useState("");
  const [EA, setEA] = React.useState("");

  const [LA, setLA] = React.useState("");
  const [lavolume, setLaVolume] = React.useState("");
  const [volumeIndex, setVolumeIndex] = React.useState("");
  const [PSLAV, setPSLAV] = React.useState("");

  const [ED, setED] = React.useState("");

  const [ED2, setED2] = React.useState("");
  const [ED3, setED3] = React.useState("");

  //   ----------------thired table data
  const [peakGradient, setPeakGradient] = React.useState("");
  const [meanGradient, setMeanGradient] = React.useState("");
  const [AVA, setAVA] = React.useState("");
  const [LVOTVTI, setLVOTVTI] = React.useState("");
  const [SVI, setSVI] = React.useState("");

  const [JetArea, setJetArea] = React.useState("");
  const [peakGradient2, setPeakGradient2] = React.useState("");
  const [VenaContract, setVenaContract] = React.useState("");
  const [meanGradient2, setMeanGradient2] = React.useState("");
  const [ERO, setERO] = React.useState("");
  const [MVA, setMVA] = React.useState("");
  const [RVOL, setRVOL] = React.useState("");

  const [TIG, setTIG] = React.useState("");

  const [Stenosis, setStenosis] = React.useState("");
  const [Regurgitation, setRegurgitation] = React.useState("");

  // -----------------fourth table data

  const [d1, setD1] = React.useState("");
  const [d2, setD2] = React.useState("");
  const [d3, setD3] = React.useState("");
  const [d4, setD4] = React.useState("");
  const [d5, setD5] = React.useState("");
  const [d6, setD6] = React.useState("");

  const [d7, setD7] = React.useState("");

  const [d8, setD8] = React.useState("");
  const [d9, setD9] = React.useState("");

  const [d10, setD10] = React.useState("");

  const [d11, setD11] = React.useState("");
  const [d12, setD12] = React.useState("");
  const [d13, setD13] = React.useState("");
  const [d14, setD14] = React.useState("");
  const [d15, setD15] = React.useState("");

  const [d16, setD16] = React.useState("");
  const [d17, setD17] = React.useState("");
  const [d18, setD18] = React.useState("");
  const [d19, setD19] = React.useState("");
  const [d20, setD20] = React.useState("");
  const [d21, setD21] = React.useState("");

  const [d22, setD22] = React.useState("");
  const [d23, setD23] = React.useState("");
  const [d24, setD24] = React.useState("");
  const [d25, setD25] = React.useState("");
  const [d26, setD26] = React.useState("");

  const [d27, setD27] = React.useState("");

  const [d28, setD28] = React.useState("");

  const [d29, setD29] = React.useState("");

  const [d30, setD30] = React.useState("");
  const [expandedReportIndex, setExpandedReportIndex] = useState({});

  const [progress, setProgress] = useState(0);
  const [uploading, setUploading] = useState(false);
  const [reports, setReports] = useState([]);

  const [reportId, setReportId] = useState("");
  const [requestId, setRequestId] = useState("");
  const [openSuccess, setOpenSuccess] = useState(false);
  const [status,setStatus] = useState('')
  /////////////second report general data///////////
  const [calibration, setCalibration] = useState("");
  const [calibration2, setCalibration2] = useState("");
  const [reason, setReason] = useState("");
  const [physician2, setPhysician2] = React.useState("");
  const [hr2, setHr2] = React.useState("");
  const [bp12, setBp12] = React.useState("");
  const [bp22, setBp22] = React.useState("");
  const [spo22, setSpo22] = React.useState("");
  ///////////////second report general data/////////////////
  const [ECG, setECG] = useState("");

  ///////////////second report summery data/////////////////
  const [diagnisis, setDiagnisis] = useState("");
  const [recommendation,setRecommendation]= useState('')
  const [extraNotes,setExtraNotes]= useState('')


  const [zipFile,setZipFile]= useState([])

  const [add,setAdd] = useState(false)

  const [addFirstReport,setAddFirstReport] = useState(false)

  const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
    sx={{display:'flex', flexWrap:'wrap', justifyContent:'center', alignItems:'center'}}
      expandIcon={
        <>
       {!expanding && <ArrowDropDownIcon
          sx={{ color: "#008080", fontSize: "40px", mb: "30px" }}
        />}
        </>
      }
      {...props}
    />
  ))(() => ({
    flexDirection: "row-reverse",
    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
      transform: !expanding && "rotate(180deg)",
      marginBottom: !expanding && "60px" ,
    },
  }));

  React.useEffect(() => {
    const token = localStorage.getItem("token");
    const lang = localStorage.getItem("lang");
    const id = localStorage.getItem("id");

    if (type === "technician" || type === "supervisor" || type === "doctor") {
      axios
        .get(
          `${process.env.REACT_APP_API_URL}get-patient-details?patient_id=${data}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Accept-Language": lang,
            },
          }
        )
        .then((res) => {
          setOpen(false);
          setDetails([
            { birthdate: res.data.data.birthdate },
            { first_name: res.data.data.first_name },
            { gender: res.data.data.gender },
            { id_number: res.data.data.id_number },
            { last_name: res.data.data.last_name },
            { phone: res.data.data.phone },
          ]);
          setRequests(res.data.data.requests);
        });
    } else {
      axios
        .get(
          `${process.env.REACT_APP_API_URL}get-patient-details?patient_id=${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Accept-Language": lang,
            },
          }
        )
        .then((res) => {
          setOpen(false);
          setDetails([
            { birthdate: res.data.data.birthdate },
            { first_name: res.data.data.first_name },
            { gender: res.data.data.gender },
            { id_number: res.data.data.id_number },
            { last_name: res.data.data.last_name },
            { phone: res.data.data.phone },
          ]);
          setRequests(res.data.data.requests);
        });
    }
    window.scrollTo(0, 0);
  }, []);
  const toPDF = async (request,index) => {
    const container = document.createElement("div");
    container.style.position = "absolute";
    container.style.top = "-10000px";
    document.body.appendChild(container);

    const root = createRoot(container);
    if(showFirstReport){
    root.render(<PDFContent requests={requests[request]} report={requests[request].report[index]} details={details} />);
  }
  else{
    root.render(<SecondReportPdf requests={requests[request]} report={requests[request].report[index]}  details={details}/>);
  }
    await new Promise((resolve) => setTimeout(resolve, 100));

    const input = container.querySelector("#pdf-content");

    if (!input) {
      root.unmount();
      document.body.removeChild(container);
      return;
    }

    const canvas = await html2canvas(input, {
      scale: 2,
      useCORS: true,  
      allowTaint: false,
      logging: true,   
      windowWidth: input.scrollWidth, 
      windowHeight: input.scrollHeight 
    });    
    const imgData = canvas.toDataURL("image/png");
    let pdf ;
    if (showFirstReport){    
     pdf = new jsPDF("p", "mm", [200, 310], true);
    }
    else{
     pdf = new jsPDF("p", "mm", [212, 310], true);
  }
    const imgWidth = 250;
    const pageHeight = 310;
    const imgHeight = (canvas.height * imgWidth) / canvas.width;

    if (imgHeight > pageHeight) {
      const scaleRatio = pageHeight / imgHeight;
      const adjustedHeight = imgHeight * scaleRatio;
      const adjustedWidth = imgWidth * scaleRatio;
      pdf.addImage(imgData, "PNG", 0, 0, adjustedWidth, adjustedHeight);
    } else {
      pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight);
    }

    pdf.save("Report.pdf");

    root.unmount();
    document.body.removeChild(container);
  };

  const handleData = (r) => {
    setAdd(false)
    setPhysician(r.referring_physician);
    setIndication(r.indication);
    setHr(r.hr);
    setBp1(r.bp_1);
    setBp2(r.bp_2);
    setSpo2(r.spo2);
    setRoot(r.functions.aorta_root);
    setAscending(r.functions.aorta_ascending);
    setArch(r.functions.aorta_arch);
    setIVC(r.functions.aorta_ivc);
    setEDd(r.functions.left_ventrical_edd);
    setEDs(r.functions.left_ventrical_eds);
    setIVSDd(r.functions.left_ventrical_ivsdd);
    setIVSSd(r.functions.left_ventrical_ivssd);
    setEF(r.functions.left_ventrical_ef);
    setLVPWD(r.functions.left_ventrical_lvpwd);
    setLVPWS(r.functions.left_ventrical_lvpws);
    setEWave(r.functions.left_ventrical_ewave);
    setAWave(r.functions.left_ventrical_awave);
    setEA(r.functions.left_ventrical_ea);
    setLA(r.functions.left_atrium_la_area);
    setLaVolume(r.functions.left_atrium_la_volume);
    setVolumeIndex(r.functions.left_atrium_volume_index);
    setPSLAV(r.functions.left_atrium_pslav);
    setED(r.functions.right_ventrical_ed_diameter);
    setED2(r.functions.right_atrium_size);
    setED3(r.functions.right_atrium_area);
    setPeakGradient(r.valves.aortic_peak_gradient);
    setMeanGradient(r.valves.aortic_mean_gradient);
    setAVA(r.valves.aortic_ava);
    setLVOTVTI(r.valves.aortic_lvotvti);
    setSVI(r.valves.aortic_svi);
    setJetArea(r.valves.mitral_jet_area);
    setPeakGradient2(r.valves.mitral_peak_gradient);
    setVenaContract(r.valves.mitral_vena_contract);
    setMeanGradient2(r.valves.mitral_mean_gradient);
    setRVOL(r.valves.mitral_rvol);
    setMVA(r.valves.mitral_mva);
    setERO(r.valves.mitral_ero);
    setTIG(r.valves.tricuspid_tig_gradient);
    setRegurgitation(r.valves.pulmonic_regurgitation);
    setStenosis(r.valves.pulmonic_stenosis);
    setD1(r.summary.left_ventricle_size);
    setD2(r.summary.left_ventricle_function);
    setD3(r.summary.left_ventricle_lvh);
    setD4(r.summary.left_ventricle_field_1);
    setD5(r.summary.left_ventricle_field_2);
    setD6(r.summary.left_ventricle_field_3);
    setD7(r.summary.diastolic_function);
    setD8(r.summary.right_ventricle_size);
    setD9(r.summary.right_ventricle_function);
    setD10(r.summary.left_atrium_size);
    setD11(r.summary.mitral_valve);
    setD12(r.summary.mitral_leaflet);
    setD13(r.summary.mitral_regurgitation);
    setD14(r.summary.mitral_stenosis);
    setD15(r.summary.mitral_field);
    setD16(r.summary.aortic_valve);
    setD17(r.summary.aortic_field_1);
    setD18(r.summary.aortic_field_2);
    setD19(r.summary.aortic_regurgitation);
    setD20(r.summary.aortic_stenosis);
    setD21(r.summary.aortic_field_3);
    setD22(r.summary.tricuspid_valve);
    setD23(r.summary.tricuspid_regurgitation);
    setD24(r.summary.tricuspid_stenosis);
    setD25(r.summary.tricuspid_pulmonary);
    setD26(r.summary.tricuspid_tig);
    setD27(r.summary.pulmonary_valve);
    setD28(r.summary.pericardial_effusion);
    setD29(r.summary.extra_notes)
    {
      Object.keys(r.attachment).length > 0
        ? setD30(r.attachment)
        : setD30({});
    }
    {
      Object.keys(r.attachment).length > 0
        ? localStorage.setItem("dicom", r.attachment)
        : localStorage.setItem("dicom", null);
    }
  };


  const handleMainChange =(index,r) =>{
    setStatus(r.status)
    setReports(r.report)
     if(r.report.length === 0 && r.status !== 'Filling Form'){
      setAlert(true)
    }
    else if(type === 'technician' && r.report.length > 0){
      setAddFirstReport(false)
    }
    else if(type === 'technician' && r.status === 'Filling Form' && showFirstReport && r.report.length === 0){
      setAddFirstReport(true)
      setAdd(true)
      setExpanding(true)
      setShow(true)
      setShowFirstReport(true)
      setAlert(false)
        setPhysician("");
        setIndication("");
        setHr("");
        setBp1("");
        setBp2("");
        setSpo2("");
        setRoot("");
        setAscending("");
        setArch("");
        setIVC("");
        setEDd("");
        setEDs("");
        setIVSDd("");
        setIVSSd("");
        setEF("");
        setLVPWD("");
        setLVPWS("");
        setEWave("");
        setAWave("");
        setEA("");
        setLA("");
        setLaVolume("");
        setVolumeIndex("");
        setPSLAV("");
        setED("");
        setED2("");
        setED3("");
        setPeakGradient("");
        setMeanGradient("");
        setAVA("");
        setLVOTVTI("");
        setSVI("");
        setJetArea("");
        setPeakGradient2("");
        setVenaContract("");
        setMeanGradient2("");
        setRVOL("");
        setMVA("");
        setERO("");
        setTIG("");
        setRegurgitation("");
        setStenosis("");
        setD1("");
        setD2("");
        setD3("");
        setD4("");
        setD5("");
        setD6("");
        setD7("");
        setD8("");
        setD9("");
        setD10("");
        setD11("");
        setD12("");
        setD13("");
        setD14("");
        setD15("");
        setD16("");
        setD17("");
        setD18("");
        setD19("");
        setD20("");
        setD21("");
        setD22("");
        setD23("");
        setD24("");
        setD25("");
        setD26("");
        setD27("");
        setD28("");
        setD29("");
        setD30("");
          }
          else if(type === 'patient' && r.report.length === 0){
            setAlert(true)
          }
          else if(type === 'doctor' && r.report.length === 0){
            setAlert(true)
          }
    else{
      setAlert(false)
    }
    if(r.is_other === true){
      setShowFirstReport(false)
    }
    else{
      setShowFirstReport(true)
    }
   
    setRequestId(r.id);
    setExpandedIndex(index === expandedIndex ? null : index);
  }

  const handleSecondReportData =(r) =>{
    setAdd(false)
    setPhysician2(r.referring_physician)
    setHr2(r.hr)
    setSpo22(r.spo2)
    setBp12(r.bp_1)
    setBp22(r.bp_2)
    setCalibration(r.report_other.calibration_one)
    setCalibration2(r.report_other.calibration_two)
    setReason(r.report_other.reason)
    setECG(r.report_other.interpretation)
    setDiagnisis(r.report_other.diagnosis)
    setRecommendation(r.report_other.recommendation)
    setExtraNotes(r.report_other.extra_note)
    if(r.attachments.length > 0){
    setZipFile(r.attachments)
  }
  else{
    setZipFile('')
  }
}

  const handleAddNewReport = (requestIndex) => {
    if(showFirstReport){
      setExpanding(true)
      setShow(true)
      setAlert(false)
        setPhysician("");
        setIndication("");
        setHr("");
        setBp1("");
        setBp2("");
        setSpo2("");
        setRoot("");
        setAscending("");
        setArch("");
        setIVC("");
        setEDd("");
        setEDs("");
        setIVSDd("");
        setIVSSd("");
        setEF("");
        setLVPWD("");
        setLVPWS("");
        setEWave("");
        setAWave("");
        setEA("");
        setLA("");
        setLaVolume("");
        setVolumeIndex("");
        setPSLAV("");
        setED("");
        setED2("");
        setED3("");
        setPeakGradient("");
        setMeanGradient("");
        setAVA("");
        setLVOTVTI("");
        setSVI("");
        setJetArea("");
        setPeakGradient2("");
        setVenaContract("");
        setMeanGradient2("");
        setRVOL("");
        setMVA("");
        setERO("");
        setTIG("");
        setRegurgitation("");
        setStenosis("");
        setD1("");
        setD2("");
        setD3("");
        setD4("");
        setD5("");
        setD6("");
        setD7("");
        setD8("");
        setD9("");
        setD10("");
        setD11("");
        setD12("");
        setD13("");
        setD14("");
        setD15("");
        setD16("");
        setD17("");
        setD18("");
        setD19("");
        setD20("");
        setD21("");
        setD22("");
        setD23("");
        setD24("");
        setD25("");
        setD26("");
        setD27("");
        setD28("");
        setD29("");
        setD30("");
        setShowSubmit(true)
    }
    else{
    setShowSubmit(false)
    setPhysician2('')
    setHr2('')
    setSpo22('')
    setBp12('')
    setBp22('')
    setCalibration('')
    setCalibration2('')
    setReason('')
    setECG('')
    setDiagnisis('')
    setRecommendation('')
    setExtraNotes('')
    setAdd(true)
  }

    const newReport = { title: "", content: "" };
    
    setReports((prevReports) => {
      const updatedReports = [...prevReports];
      updatedReports.push(newReport);
      return updatedReports;
    });
    
    setExpandedReportIndex((prev) => ({
      ...prev,
      [requestIndex]: reports.length, 
    }));
      setShow(true);
  };
  const handleAccordionChange = (requestIndex, reportIndex , r) => {
    setExpandedReportIndex((prevState) => ({
      ...prevState,
      [requestIndex]: prevState[requestIndex] === reportIndex ? null : reportIndex,
    }));   
    setReportId(r.id)
      if (type === "technician" && status !== "Filling Form" && showFirstReport) {
        setShowSubmit(false);
        setShow(false);
        handleData(r);
    } 
    else if (type === "technician" && status !== "Filling Form" && !showFirstReport) {
        setShowSubmit(false);
        setShow(false);
        handleSecondReportData(r);
    } 
    else if (type === "supervisor" && status === "Needs Approval" && showFirstReport) {
       setReportId(r.id);
       handleData(r);
       setShow(true)
       setShowSubmit(true);
    }
    else if (type === "supervisor" && status === "Needs Approval" && !showFirstReport) {
      setShowSubmit(true);
       setReportId(r.id);
       handleSecondReportData(r);
       setShow(true)
    }
    else if (type === "supervisor" && showFirstReport && status === "Needs Approval" && r.status_id !== 'Needs Approval') {
      const createdAtDate = new Date(r.created_at);
      const currentDate = new Date();
      const timeDifference = currentDate.getTime() - createdAtDate.getTime();
      const sixHoursInMs = 6 * 60 * 60 * 1000;
      if (timeDifference < sixHoursInMs) {
        setReportId(r.id);
        handleData(r);
        setShow(true)
        setShowSubmit(true);
      }
      else{
        setShow(false)
        handleData(r);
      }
   }
   else if (type === "supervisor" && !showFirstReport && status === "Needs Approval" && r.status_id !== 'Needs Approval' ) {
    const createdAtDate = new Date(r.created_at);
    const currentDate = new Date();
    const timeDifference = currentDate.getTime() - createdAtDate.getTime();
    const sixHoursInMs = 6 * 60 * 60 * 1000;
    if (timeDifference < sixHoursInMs) {
      setReportId(r.id);
      handleSecondReportData(r);
      setShow(true)
      setShowSubmit(true);
    }
    else{
      setShow(false)
      handleSecondReportData(r);
    }
 }
   else if (showFirstReport) {
      setShow(false);
      setShowSubmit(false);
      setReportId(r.id);
      handleData(r);
    }
    else {
      setShow(false);
      setShowSubmit(false);
      setReportId(r.id);
      handleSecondReportData(r);
    }
  };

  const handleApprove = () => {
    const token = localStorage.getItem("token");
    const lang = localStorage.getItem("lang");
    const SubmitData = {
      _method: "PUT",
      main_report: {
        referring_physician: physician,
        indication: indication,
        hr: hr,
        bp_1: bp1,
        bp_2: bp2,
        spo2: spo2,
      },
      report_functions: {
        aorta_root: root,
        aorta_ascending: ascending,
        aorta_arch: arch,
        aorta_ivc: IVC,
        left_ventrical_edd: EDd,
        left_ventrical_eds: EDs,
        left_ventrical_ivsdd: IVSDd,
        left_ventrical_ivssd: IVSSd,
        left_ventrical_ef: EF,
        left_ventrical_lvpwd: LVPWD,
        left_ventrical_lvpws: LVPWS,
        left_ventrical_ewave: EWave,
        left_ventrical_awave: AWave,
        left_ventrical_ea: EA,
        right_ventrical_ed_diameter: ED,
        left_atrium_la_area: LA,
        left_atrium_la_volume: lavolume,
        left_atrium_volume_index: volumeIndex,
        left_atrium_pslav: PSLAV,
        right_atrium_size: ED2,
        right_atrium_area: ED3,
      },
      report_valves: {
        aortic_peak_gradient: peakGradient,
        aortic_mean_gradient: meanGradient,
        aortic_ava: AVA,
        aortic_lvotvti: LVOTVTI,
        aortic_svi: SVI,
        mitral_jet_area: JetArea,
        mitral_vena_contract: VenaContract,
        mitral_rvol: RVOL,
        mitral_ero: ERO,
        mitral_peak_gradient: peakGradient2,
        mitral_mean_gradient: meanGradient2,
        mitral_mva: MVA,
        tricuspid_tig_gradient: TIG,
        pulmonic_regurgitation: Regurgitation,
        pulmonic_stenosis: Stenosis,
      },
      report_summary: {
        left_ventricle_size: d1,
        left_ventricle_function: d2,
        left_ventricle_lvh: d3,
        left_ventricle_field_1: d4,
        left_ventricle_field_2: d5,
        left_ventricle_field_3: d6,
        diastolic_function: d7,
        right_ventricle_size: d8,
        right_ventricle_function: d9,
        left_atrium_size: d10,
        mitral_valve: d11,
        mitral_leaflet: d12,
        mitral_regurgitation: d13,
        mitral_field: d15,
        mitral_stenosis: d14,
        aortic_valve: d16,
        aortic_field_1: d17,
        aortic_field_2: d18,
        aortic_field_3: d21,
        aortic_regurgitation: d19,
        aortic_stenosis: d20,
        tricuspid_valve: d22,
        tricuspid_regurgitation: d23,
        tricuspid_stenosis: d24,
        tricuspid_pulmonary: d25,
        tricuspid_tig: d26,
        pulmonary_valve: d27,
        pericardial_effusion: d28,
        extra_notes: d29,
      },
      report_id: parseInt(reportId),
    };
    setUploading(true);
    axios
      .post(`${process.env.REACT_APP_API_URL}update-report`, SubmitData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Accept-Language": lang,
        },
        onUploadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent;
          const percentage = Math.round((loaded * 100) / total);
          setProgress(percentage);
        },
      })
      .then((res) => {
        if (res.data.status === true) {
          setUploading(false);
          setOpenSuccess(true);
          setTimeout(() => {
            window.location.reload(true);
          }, 2100);
        }
      });
  };

  const handleSend = () => {
    const token = localStorage.getItem("token");
    const lang = localStorage.getItem("lang");

    const SubmitData = {
      main_report: {
        request_id: requestId,
        referring_physician: physician,
        indication: indication,
        hr: hr,
        bp_1: bp1,
        bp_2: bp2,
        spo2: spo2,
      },
      report_functions: {
        aorta_root: root,
        aorta_ascending: ascending,
        aorta_arch: arch,
        aorta_ivc: IVC,
        left_ventrical_edd: EDd,
        left_ventrical_eds: EDs,
        left_ventrical_ivsdd: IVSDd,
        left_ventrical_ivssd: IVSSd,
        left_ventrical_ef: EF,
        left_ventrical_lvpwd: LVPWD,
        left_ventrical_lvpws: LVPWS,
        left_ventrical_ewave: EWave,
        left_ventrical_awave: AWave,
        left_ventrical_ea: EA,
        right_ventrical_ed_diameter: ED,
        left_atrium_la_area: LA,
        left_atrium_la_volume: lavolume,
        left_atrium_volume_index: volumeIndex,
        left_atrium_pslav: PSLAV,
        right_atrium_size: ED2,
        right_atrium_area: ED3,
      },
      report_valves: {
        aortic_peak_gradient: peakGradient,
        aortic_mean_gradient: meanGradient,
        aortic_ava: AVA,
        aortic_lvotvti: LVOTVTI,
        aortic_svi: SVI,
        mitral_jet_area: JetArea,
        mitral_vena_contract: VenaContract,
        mitral_rvol: RVOL,
        mitral_ero: ERO,
        mitral_peak_gradient: peakGradient2,
        mitral_mean_gradient: meanGradient2,
        mitral_mva: MVA,
        tricuspid_tig_gradient: TIG,
        pulmonic_regurgitation: Regurgitation,
        pulmonic_stenosis: Stenosis,
      },
      report_summary: {
        left_ventricle_size: d1,
        left_ventricle_function: d2,
        left_ventricle_lvh: d3,
        left_ventricle_field_1: d4,
        left_ventricle_field_2: d5,
        left_ventricle_field_3: d6,
        diastolic_function: d7,
        right_ventricle_size: d8,
        right_ventricle_function: d9,
        left_atrium_size: d10,
        mitral_valve: d11,
        mitral_leaflet: d12,
        mitral_regurgitation: d13,
        mitral_field: d15,
        mitral_stenosis: d14,
        aortic_valve: d16,
        aortic_field_1: d17,
        aortic_field_2: d18,
        aortic_field_3: d21,
        aortic_regurgitation: d19,
        aortic_stenosis: d20,
        tricuspid_valve: d22,
        tricuspid_regurgitation: d23,
        tricuspid_stenosis: d24,
        tricuspid_pulmonary: d25,
        tricuspid_tig: d26,
        pulmonary_valve: d27,
        pericardial_effusion: d28,
        extra_notes: d29,
      },
    };

    const appendFormData = (
      data,
      formData = new FormData(),
      parentKey = ""
    ) => {
      if (typeof data === "object" && !(data instanceof File)) {
        Object.keys(data).forEach((key) => {
          const fullKey = parentKey ? `${parentKey}[${key}]` : key;
          appendFormData(data[key], formData, fullKey);
        });
      } else {
        formData.append(parentKey, data);
      }

      return formData;
    };

    const formData = appendFormData(SubmitData);
    formData.append("attachment", d30);
    setUploading(true);
    axios
      .post(`${process.env.REACT_APP_API_URL}store-report`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Accept-Language": lang,
        },
        onUploadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent;
          const percentage = Math.round((loaded * 100) / total);
          setProgress(percentage);
        },
      })
      .then((res) => {
        setUploading(false);
        if (res.data.status === true) {
          setOpenSuccess(true);
          setTimeout(() => {
            window.location.reload(true);
          }, 2100);
        }
      })
      .catch((error) => {
        setUploading(false);
        console.error("Error uploading the file:", error);
      });
  };

const handleApproveSecondReport =() =>{
  const token = localStorage.getItem("token");
  const lang = localStorage.getItem("lang");
 const formData = new FormData()
 formData.append('_method','PUT')
 formData.append('request_id',requestId)
 formData.append('report_id',reportId)
 formData.append('referring_physician',physician2)
 formData.append('calibration_one',calibration)
 formData.append('calibration_two',calibration2)
 formData.append('hr',hr2)
 formData.append('bp_1',bp12)
 formData.append('bp_2',bp22)
 formData.append('reason',reason)
 formData.append('diagnosis',diagnisis)
 formData.append('recommendation',recommendation)
 formData.append('extra_note',extraNotes)
  for(var i = 0 ; i < zipFile.length ; i ++ ){
 formData.append('attachments[]',zipFile[i])
}

 formData.append('interpretation',ECG)
 formData.append('spo2',spo22)
 setUploading(true);

  axios
    .post(`${process.env.REACT_APP_API_URL}update-report-other`, formData, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Accept-Language": lang,
        "Accept":"application/json"
      },
      onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;
        const percentage = Math.round((loaded * 100) / total);
        setProgress(percentage);
      },
    })
    .then((res) => {
      setUploading(false);
      if (res.data.status === true) {
        setOpenSuccess(true);
        setTimeout(() => {
          window.location.reload(true);
        }, 2100);
      }
    })
    .catch((error) => {
      setUploading(false);
      console.error("Error uploading the file:", error);
    });
}

  const handleSendSecondReport =() =>{
    const token = localStorage.getItem("token");
    const lang = localStorage.getItem("lang");
   const formData = new FormData()
   formData.append('request_id',requestId)
   formData.append('referring_physician',physician2)
   formData.append('calibration_one',calibration)
   formData.append('calibration_two',calibration2)
   formData.append('hr',hr2)
   formData.append('bp_1',bp12)
   formData.append('bp_2',bp22)
   formData.append('reason',reason)
   formData.append('diagnosis',diagnisis)
   formData.append('recommendation',recommendation)
   formData.append('extra_note',extraNotes)
    for(var i = 0 ; i < zipFile.length ; i ++ ){
   formData.append('attachments[]',zipFile[i])
  }

   formData.append('interpretation',ECG)
   formData.append('spo2',spo22)
   setUploading(true);

    axios
      .post(`${process.env.REACT_APP_API_URL}store-report-other`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Accept-Language": lang,
          "Accept":"application/json"
        },
        onUploadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent;
          const percentage = Math.round((loaded * 100) / total);
          setProgress(percentage);
        },
      })
      .then((res) => {
        setUploading(false);
        if (res.data.status === true) {
          setOpenSuccess(true);
          setTimeout(() => {
            window.location.reload(true);
          }, 2100);
        }
      })
      .catch((error) => {
        setUploading(false);
        console.error("Error uploading the file:", error);
      });
  }

  return (
    <div
      style={{
        backgroundColor: "#F4F4F4",
        minHeight: windowHeight.current,
        paddingBottom: "50px",
      }}
    >
      <ButtonBar />
      {open && <LoadingPage open={open} />}

      {!open && (
        <Container maxWidth="lg">
          <Box sx={{ width: "100%", display: "flex", justifyContent: "end" }}>
            {type === "doctor" ? (
              <Button
                sx={{
                  backgroundColor: "#008080",
                  color: "white",
                  textTransform: "none",
                  fontSize: "15px",
                  p: "5px",
                  width: "200px",
                  mt: "30px",
                  "&:hover": {
                    backgroundColor: "#008080",
                    color: "white",
                  },
                }}
                onClick={() => {
                  navigate("/requestnewservice");
                }}
              >
                {t("Request Another Service")}
              </Button>
            ) : null}
          </Box>

          {type !== "patient" ? <PatientDetails details={details} /> : null}
          <div>
            {requests.map((r, requestIndex) => (
              <div id={`accordion-content-${requestIndex}`} key={requestIndex}>
                <Container
                  maxWidth="lg"
                  sx={{
                    mt: "30px",
                    bgcolor: "white",
                    pb: "30px",
                    position: "relative",
                  }}
                >
                  <Accordion
                    sx={{ boxShadow: "none" }}
                    expanded={expandedIndex === requestIndex}
                    onChange={() => handleMainChange(requestIndex,r)}
                  >
                    <AccordionSummary>
                      <EchoDetails requests={r} />
                    </AccordionSummary>
                    {type === "technician" && r.is_other === true && <Button  
                     sx={{
                                backgroundColor: "#008080",
                                color: "white",
                                textTransform: "none",
                                fontSize: "15px",
                                p: "5px",
                                width: "200px",
                                ml:'20px',
                                "&:hover": {
                                  backgroundColor: "#008080",
                                  color: "white",
                                },
                              }}
                              onClick={() => handleAddNewReport(requestIndex)}
                              >
                              Add New Report
                              </Button>}
                              {type === "technician" && r.is_other === false && addFirstReport && <Button  
                     sx={{
                                backgroundColor: "#008080",
                                color: "white",
                                textTransform: "none",
                                fontSize: "15px",
                                p: "5px",
                                width: "200px",
                                ml:'20px',
                                "&:hover": {
                                  backgroundColor: "#008080",
                                  color: "white",
                                },
                              }}
                              onClick={() => handleAddNewReport(requestIndex)}
                              >
                              Add New Report
                              </Button>}
                    <AccordionDetails>
                    {reports.map((reporting, reportIndex) => (
              <Accordion
                key={`${requestIndex}-${reportIndex}`}
                sx={{ boxShadow: "none" }}
                expanded={expandedReportIndex[requestIndex] === reportIndex}
                onChange={() =>
                  handleAccordionChange(requestIndex, reportIndex, reporting)
                }
              >
                  <AccordionSummary >
                     {reporting.created_at ?  <p style={{ fontSize: "20px", fontWeight: "bolder", color: "#008080" }}>
                        Report: {" "} 
                        {reporting.created_at.split('T')[0]}
                        </p> :   <p style={{ fontSize: "20px", fontWeight: "bolder", color: "#008080" }}>
                       {/* New Report */}
                        </p>}
                    </AccordionSummary>
                      <Stack
                        sx={{ justifyContent: "center", alignItems: "center" }}
                        gap="30px"
                      >
                        {!alert && (
                          <>
                          {showFirstReport && <>  
                          <GeneralTable
                              physician={physician}
                              setPhysician={setPhysician}
                              indication={indication}
                              setIndication={setIndication}
                              hr={hr}
                              setHr={setHr}
                              bp1={bp1}
                              setBp1={setBp1}
                              bp2={bp2}
                              setBp2={setBp2}
                              spo2={spo2}
                              setSpo2={setSpo2}
                              show={show}
                            />
                            <SecondTable
                              root={root}
                              setRoot={setRoot}
                              ascending={ascending}
                              setAscending={setAscending}
                              arch={arch}
                              setArch={setArch}
                              IVC={IVC}
                              setIVC={setIVC}
                              EDd={EDd}
                              setEDd={setEDd}
                              LVPWD={LVPWD}
                              setLVPWD={setLVPWD}
                              EDs={EDs}
                              setEDs={setEDs}
                              LVPWS={LVPWS}
                              setLVPWS={setLVPWS}
                              IVSDd={IVSDd}
                              setIVSDd={setIVSDd}
                              EWave={EWave}
                              setEWave={setEWave}
                              IVSSd={IVSSd}
                              setIVSSd={setIVSSd}
                              AWave={AWave}
                              setAWave={setAWave}
                              set={AWave}
                              EF={EF}
                              setEF={setEF}
                              EA={EA}
                              setEA={setEA}
                              LA={LA}
                              setLA={setLA}
                              lavolume={lavolume}
                              setLaVolume={setLaVolume}
                              volumeIndex={volumeIndex}
                              setVolumeIndex={setVolumeIndex}
                              PSLAV={PSLAV}
                              setPSLAV={setPSLAV}
                              ED={ED}
                              setED={setED}
                              ED2={ED2}
                              setED2={setED2}
                              ED3={ED3}
                              setED3={setED3}
                              show={show}
                            />
                            <ThiredTable
                              peakGradient={peakGradient}
                              setPeakGradient={setPeakGradient}
                              meanGradient={meanGradient}
                              setMeanGradient={setMeanGradient}
                              AVA={AVA}
                              setAVA={setAVA}
                              LVOTVTI={LVOTVTI}
                              setLVOTVTI={setLVOTVTI}
                              SVI={SVI}
                              setSVI={setSVI}
                              JetArea={JetArea}
                              setJetArea={setJetArea}
                              peakGradient2={peakGradient2}
                              setPeakGradient2={setPeakGradient2}
                              VenaContract={VenaContract}
                              setVenaContract={setVenaContract}
                              ERO={ERO}
                              setERO={setERO}
                              MVA={MVA}
                              setMVA={setMVA}
                              RVOL={RVOL}
                              setRVOL={setRVOL}
                              TIG={TIG}
                              setTIG={setTIG}
                              Stenosis={Stenosis}
                              setStenosis={setStenosis}
                              Regurgitation={Regurgitation}
                              setRegurgitation={setRegurgitation}
                              meanGradient2={meanGradient2}
                              setMeanGradient2={setMeanGradient2}
                              show={show}
                            />
                            <FourthTable
                              d1={d1}
                              setD1={setD1}
                              d2={d2}
                              setD2={setD2}
                              d3={d3}
                              setD3={setD3}
                              d4={d4}
                              setD4={setD4}
                              d5={d5}
                              setD5={setD5}
                              d6={d6}
                              setD6={setD6}
                              d7={d7}
                              setD7={setD7}
                              d8={d8}
                              setD8={setD8}
                              d9={d9}
                              setD9={setD9}
                              d10={d10}
                              setD10={setD10}
                              d11={d11}
                              setD11={setD11}
                              d12={d12}
                              setD12={setD12}
                              d13={d13}
                              setD13={setD13}
                              d14={d14}
                              setD14={setD14}
                              d15={d15}
                              setD15={setD15}
                              d16={d16}
                              setD16={setD16}
                              d17={d17}
                              setD17={setD17}
                              d18={d18}
                              setD18={setD18}
                              d19={d19}
                              setD19={setD19}
                              d20={d20}
                              setD20={setD20}
                              d21={d21}
                              setD21={setD21}
                              d22={d22}
                              setD22={setD22}
                              d23={d23}
                              setD23={setD23}
                              d24={d24}
                              setD24={setD24}
                              d25={d25}
                              setD25={setD25}
                              d26={d26}
                              setD26={setD26}
                              d27={d27}
                              setD27={setD27}
                              d28={d28}
                              setD28={setD28}
                              d29={d29}
                              setD29={setD29}
                              d30={d30}
                              setD30={setD30}
                              show={show}
                              status={r.status}
                            />
                            </>}
                           {!showFirstReport && <>
                            <SecondGeneralTable
                              physician2={physician2}
                              setPhysician2={setPhysician2}
                              calibration={calibration}
                              calibration2={calibration2}
                              setCalibration2={setCalibration2}
                              setCalibration={setCalibration}
                              hr2={hr2}
                              setHr2={setHr2}
                              bp12={bp12}
                              setBp12={setBp12}
                              setBp22={setBp22}
                              bp22={bp22}
                              spo22={spo22}
                              setSpo22={setSpo22}
                              reason={reason}
                              setReason={setReason}
                              show={show}
                            />
                            <ECGTable ECG={ECG} setECG={setECG} show={show} />
                            <SecondSummeryTable diagnisis={diagnisis} 
                            setDiagnisis={setDiagnisis}
                            recommendation={recommendation}
                            setRecommendation={setRecommendation}
                            extraNotes={extraNotes}
                            setExtraNotes={setExtraNotes}
                            show={show}
                            />
                        <SecondAttachTable zipFile={zipFile} setZipFile={setZipFile} show={show} />

                        {type === "technician" && add && 
                            <Box
                            sx={{
                              display: "flex",
                              justifyContent: "end",
                              width: "100%",
                            }}
                          >
                          <Button
                              sx={{
                                backgroundColor: "#008080",
                                color: "white",
                                textTransform: "none",
                                fontSize: "15px",
                                p: "5px",
                                width: "200px",
                                mt: "30px",
                                "&:hover": {
                                  backgroundColor: "#008080",
                                  color: "white",
                                },
                              }}
                              onClick={handleSendSecondReport}
                            >
                              {t("Submit")}
                            </Button>
                            </Box>}
                        
                        </>}
                        

                            {type === "patient" ||
                            type === "doctor" ||
                            type === "supervisor" ||
                            (type === "technician" &&
                              r.status !== "Filling Form") ? (
                              <>
                                {Object.keys(d30).length > 0 && showFirstReport && (
                                  <AttachementTable />
                                )}
                              </>
                            ) : null}
                          </>
                        )}
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "end",
                            width: "100%",
                          }}
                        >
                          {type === "technician" && showSubmit && (
                            <Button
                              sx={{
                                backgroundColor: "#008080",
                                color: "white",
                                textTransform: "none",
                                fontSize: "15px",
                                p: "5px",
                                width: "200px",
                                mt: "30px",
                                "&:hover": {
                                  backgroundColor: "#008080",
                                  color: "white",
                                },
                              }}
                              onClick={handleSend}
                            >
                              {t("Add Report")}
                            </Button>
                          )}

                          {type === "supervisor" && showSubmit && showFirstReport && (
                            <>
                              <Button
                                sx={{
                                  backgroundColor: "#008080",
                                  color: "white",
                                  textTransform: "none",
                                  fontSize: "15px",
                                  p: "5px",
                                  width: "200px",
                                  mt: "30px",
                                  "&:hover": {
                                    backgroundColor: "#008080",
                                    color: "white",
                                  },
                                }}
                                onClick={handleApprove}
                              >
                                {t("Approve")}
                              </Button>
                            </>
                          )}
                          {type === "supervisor" && showSubmit && !showFirstReport && (
                            <>
                              <Button
                                sx={{
                                  backgroundColor: "#008080",
                                  color: "white",
                                  textTransform: "none",
                                  fontSize: "15px",
                                  p: "5px",
                                  width: "200px",
                                  mt: "30px",
                                  "&:hover": {
                                    backgroundColor: "#008080",
                                    color: "white",
                                  },
                                }}
                                onClick={handleApproveSecondReport}
                              >
                                {t("Approve")}
                              </Button>
                            </>
                          )}
                        </Box>
                        {type === "patient" ? (
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "end",
                              width: "100%",
                              gap: "20px",
                            }}
                          >
                            <Button
                              sx={{
                                backgroundColor: "#008080",
                                color: "white",
                                textTransform: "none",
                                fontSize: "15px",
                                p: "5px",
                                width: "200px",
                                mt: "30px",
                                "&:hover": {
                                  backgroundColor: "#008080",
                                  color: "white",
                                },
                              }}
                              onClick={() => toPDF(requestIndex,reportIndex)}
                            >
                              {t("Download")}
                            </Button>
                          </Box>
                        ) : null}

                        {type === "patient" || type === "doctor" ? (
                          <Stack
                            direction="row"
                            sx={{
                              displa: "flex",
                              flexWrap: "wrap",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                            gap={matches ? 20 : 5}
                          >
                            {r.doctor && (
                              <p
                                style={{
                                  fontWeight: "bold",
                                  fontSize: "13px",
                                  color: "#464646",
                                }}
                              >
                                {t("Requested by : Dr")} {r.doctor}
                              </p>
                            )}
                            {r.supervisor && (
                              <p
                                style={{
                                  fontWeight: "bold",
                                  fontSize: "13px",
                                  color: "#464646",
                                }}
                              >
                                {t("Approves by : Dr")} {r.supervisor}
                              </p>
                            )}
                            {r.technician && (
                              <p
                                style={{
                                  fontWeight: "bold",
                                  fontSize: "13px",
                                  color: "#464646",
                                }}
                              >
                                {t("Technician :")} {r.technician}
                              </p>
                            )}
                          </Stack>
                        ) : null}
                      </Stack>
                      </Accordion>))}
                      {alert && (
                          <Alert
                            severity="error"
                            sx={{
                              width: matches ? "420px" : "310px",
                              marginTop: "20px",
                              mb: "20px",
                            }}
                          >
                            {t("No data yet")}
                          </Alert>
                        )}
                    </AccordionDetails>
                  </Accordion>
                </Container>
              </div>
            ))}
          </div>
        </Container>
      )}
      <Success
        open={openSuccess}
        setOpen={setOpenSuccess}
        msg={"Your request has been successfully send."}
      />
      <ContactIcon />

      {uploading && (
        <Backdrop
          sx={{
            color: "#fff",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={uploading}
        >
          <Stack
            sx={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <h1>{t("Please wait you're almost there...")}</h1>
            <h1>{progress}%</h1>
          </Stack>
        </Backdrop>
      )}
    </div>
  );
}
