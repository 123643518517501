import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import pic from "../Media/join.png";
import { Alert, Button, Grid, Stack, useMediaQuery } from "@mui/material";
import TextFieldComp from "../Components/TextFieldComp";
import { Container } from "@mui/system";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Checkbox from "@mui/material/Checkbox";
import { useNavigate } from "react-router-dom";
import im from "../Media/Vector2.png";
import im2 from "../Media/Group.png";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import axios from "axios";
import LoadingPage from "../Components/LoadingPage";
import Success from "../Components/Success";
import ContactIcon from "../Components/ContactIcon";
import Booking from "../Components/Booking";

export default function JoinNetwork() {
  const { t } = useTranslation();
  const matches = useMediaQuery("(min-width:700px)");
  const [name, setName] = useState("");
  const [lastname, setLastName] = useState("");
  const [licenseNumber, setLicenseNumber] = useState("");
  const [city, setCity] = useState("");
  const [specilization, setSpecilization] = useState([]);
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [check, setCheck] = useState(false);
  const [rows, setRows] = useState([{ id: "", btn: true, workPlace: [] }]);
  const [cities, setCities] = useState([]);
  const [specializations, setSpecializations] = useState([]);
  const [hospitals, setHospitals] = useState([]);
  const [clinics, setClinics] = useState([]);
  const [lang, setLang] = useState(localStorage.getItem("lang"));
  const [open, setOpen] = useState(true);
  const [alerting, setAlerting] = useState(false);
  const [idArray, setIdArray] = useState([]);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState(""); 

  const handleAddRow = () => {
    setRows([...rows, { id: "", btn: true, workPlace: [] }]);
  };

  const handleWorkPlaceChange = (index, value) => {
    const newRows = rows.map((row, rowIndex) => {
      if (index === rowIndex) {
        return { ...row, id: value };
      }
      return row;
    });
    setRows(newRows);
  }


  const handleButtonClick = (index, isHospital) => {
    const newRows = rows.map((row, rowIndex) => {
      if (index === rowIndex) {
        return {
          ...row,
          btn: isHospital,
          workPlace: isHospital ? hospitals : clinics,
        };
      }
      return row;
    });
    setRows(newRows);
  };

  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    axios
      .get(`${process.env.REACT_APP_API_URL}doctor-form-data`, {
        headers: {
          "Accept-Language": lang,
        },
      })
      .then((res) => {
        setCities(res.data.data.cities);
        setSpecializations(res.data.data.specializations);
        setHospitals(res.data.data.hospitals);
        setClinics(res.data.data.clinics);
        setRows([{ id: "",btn: true, workPlace: res.data.data.hospitals }])
        setOpen(false);
      });
  }, []);

  useEffect(() => {
    setIdArray(rows.map((item) => item.id));
  }, [rows]);


  const handleSend = () => {
    if (
      name &&
      phone &&
      email &&
      specilization.length > 0 &&
      lastname &&
      licenseNumber &&
      city &&
      idArray.length > 0 &&
      check
    ) {
      setAlerting(false);
      const formData = new FormData();
      formData.append("first_name", name);
      formData.append("last_name", lastname);
      formData.append("email", email);
      const formattedPhone = phone.replace(/\D/g, "");
      formData.append("phone", formattedPhone);
      formData.append("license_number", licenseNumber);
      formData.append("location_id", city);

      if (Array.isArray(specilization)) {
        specilization.forEach((id) => {
          formData.append("specialization_ids[]", id);
        });
      } else {
        formData.append("specialization_ids[]", specilization);
      }

      if (Array.isArray(idArray)) {
        idArray.forEach((id) => {
          formData.append("workplace_ids[]", id);
        });
      } else {
        formData.append("workplace_ids[]", idArray);
      }

      const jsonData = {};
      for (const [key, value] of formData.entries()) {
        if (key.endsWith("[]")) {
          const arrayKey = key.slice(0, -2);
          if (!jsonData[arrayKey]) {
            jsonData[arrayKey] = [];
          }
          jsonData[arrayKey].push(value);
        } else {
          jsonData[key] = value;
        }
      }

      axios
        .post(`${process.env.REACT_APP_API_URL}doctor-form`, jsonData, {
          headers: {
            "Accept-Language": lang,
          },
        })
        .then((res) => {
          if (res.data.status === true) {
            setOpenSuccess(true);
            setTimeout(() => {
              window.location.reload(true);
            }, 2100);
            setAlerting(false);
            setErrorMessage(""); 
            setTimeout(() => {
              navigate('/')
              }, 2100);
          }
        })
        .catch((error) => {
          if (error.response && error.response.status === 422) {
            setErrorMessage(error.response.data.message);
            setAlerting(true); 
          }
        });
    } else {
      setAlerting(true);
    }
  };

  return (
    <div style={{marginTop:'100px'}}>
      {open && <LoadingPage open={open} />}
      {!open && (
        <div style={{position:'relative'}}>
          <div
            style={{
              position: "relative",
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
              alignItems: "center",
              height: matches ?'400px' : "300px",
            }}
          >
            <img
              src={pic}
              style={{
                height: matches ?'400px' : "300px",
                objectFit: "cover",
                width: "100%",
              }}
            />
            <div className="overLay"></div>
            <p className="sections-text">{t("Join Our Network")}</p>
          </div>
          <Container
            sx={{
              mt: "50px",
              display: "flex",
              flexWrap: "wrap",
              gap: "20px",
              position: "relative",
            }}
            maxWidth="md"
          >
            {matches && (
              <>
                <img
                  src={im}
                  style={{
                    position: "absolute",
                    top: matches ? "0px" : "300px",
                    right: lang === 'en' && "-50px",
                    left : lang === 'ar' && '-50px'
                  }}
                />
                <img
                  src={im2}
                  style={{
                    position: "absolute",
                    top: matches ? "70%" : "300px",
                    right: lang === 'en' && "-50px",
                    left : lang === 'ar' && '-50px'
                  }}
                />
              </>
            )}

            <Grid container spacing={1}>
              <Grid item xs={12} sm={6}>
                <TextFieldComp
                  label={"First Name"}
                  onChange={setName}
                  value={name}
                  h={"50px"}
                  width={"80%"}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextFieldComp
                  label={"Last Name"}
                  onChange={setLastName}
                  value={lastname}
                  h={"50px"}
                  width={"80%"}
                />
              </Grid>
            </Grid>

            <Grid container spacing={1}>
              <Grid item xs={12} sm={6}>
                <TextFieldComp
                  label={"License Number"}
                  onChange={setLicenseNumber}
                  value={licenseNumber}
                  h={"50px"}
                  width={"80%"}
                  type={"number"}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextFieldComp
                  label={"City"}
                  onChange={setCity}
                  value={city}
                  h={"50px"}
                  width={"80%"}
                  data={cities}
                />
              </Grid>
            </Grid>

            <Grid container spacing={1}>
              <Grid item xs={12} sm={12}>
                <TextFieldComp
                  label={"Specializations"}
                  onChange={setSpecilization}
                  value={specilization}
                  h={"50px"}
                  width={matches ? "90%" : "80%"}
                  dataObj={specializations}
                />
              </Grid>
            </Grid>

            <Grid container spacing={1}>
              {rows.map((row, index) => (
                <React.Fragment key={index}>
                  <Grid item xs={12} sm={6}>
                    <label style={{ color: "#3D3D3D", fontSize: "15px" }}>
                      {t("Workplace Type")}
                    </label>
                    <Stack
                      direction="row"
                      sx={{
                        border: "1px solid",
                        borderColor: "#64C6C6",
                        borderRadius: "20px",
                        width: matches ? "80%" : "80%",
                        mt: "10px",
                      }}
                    >
                      <Button
                        sx={{
                          borderRadius: "20px",
                          width: "100%",
                          "&:focus": {
                            color: "white",
                            backgroundImage:
                              "linear-gradient(to right, #64C6C6 , #008080)",
                          },
                          "&:active": {
                            color: "white",
                            backgroundImage:
                              "linear-gradient(to right, #64C6C6 , #008080)",
                          },
                          backgroundImage: row.btn
                            ? "linear-gradient(to right, #64C6C6 , #008080)"
                            : "",
                          color: row.btn ? "white" : "black",
                          height: "50px",
                        }}
                        onClick={() => handleButtonClick(index, true)}
                      >
                        {t("Hospital")}
                      </Button>

                      <Button
                        sx={{
                          borderRadius: "20px",
                          width: "100%",
                          "&:focus": {
                            color: "white",
                            backgroundImage:
                              "linear-gradient(to left, #64C6C6 , #008080)",
                          },
                          backgroundImage: !row.btn
                            ? "linear-gradient(to left, #64C6C6 , #008080)"
                            : "",
                          color: !row.btn ? "white" : "black",
                        }}
                        onClick={() => handleButtonClick(index, false)}
                      >
                        {t("Clinic")}
                      </Button>
                    </Stack>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    sm={6}
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "10px",
                      justifyContent: "start",
                      alignItems: "center",
                    }}
                  >
                    <TextFieldComp
                      label={"Current Workplace"}
                      onChange={(value) => {
                        handleWorkPlaceChange(index, value);
                      }}
                      value={row.id}
                      h={"50px"}
                      width={matches ? "340px" : "280px"}
                      data={row.workPlace}
                    />
                    {index === 0 && (
                      <ControlPointIcon
                        sx={{
                          color: "#64C6C6",
                          fontSize: "30px",
                          mt: "30px",
                          cursor: "pointer",
                        }}
                        onClick={handleAddRow}
                      />
                    )}
                  </Grid>
                </React.Fragment>
              ))}
            </Grid>

            <Grid container spacing={1}>
              <Grid item xs={12} sm={6} sx={{ mt: "10px" }}>
                <label
                  style={{
                    color: "#3D3D3D",
                    fontSize: "15px",
                  }}
                >
                  {t("Phone Number")}
                </label>
                <PhoneInput
                  country={"il"}
                  enableSearch={true}
                  value={phone}
                  onChange={setPhone}
                  style={{ marginTop: "5px" }}
                  inputStyle={{
                    backgroundColor: "transparent",
                    width: "80%",
                    color: "black",
                    fontSize: "13px",
                    border: "2px solid #64C6C6",
                    borderRadius: "10px",
                    height: "50px",
                    outline: "none",
                    ":focus": {
                      border: "2px solid #64C6C6",
                    },
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextFieldComp
                  label={"Email"}
                  onChange={setEmail}
                  value={email}
                  h={"50px"}
                  width={"80%"}
                  type={"email"}
                />
              </Grid>
            </Grid>
            <Stack>
              <div
                style={{
                  display: "flex",
                  alignItems: "start",
                  justifyContent: "start",
                  flexWrap: "wrap",
                }}
              >

                <Checkbox
                  onChange={() => {
                    setCheck(!check);
                  }}
                  sx={{
                    color: "#64C6C6",
                    "&.Mui-checked": {
                      color: check ? "#64C6C6" : "#000000",
                    },
                    mt:'7px'
                  }}
                />
                <p style={{ color: "#3D3D3D", fontSize: "15px", marginTop:'15px' }}>
                  {t('I agree to the')}{" "}
                  <a
                    onClick={() => {
                      navigate("/privacypolicy");
                    }}
                    style={{ cursor: "pointer", textDecoration: "underline" }}
                  >
                    {t('Terms & Conditions')}
                  </a>
                </p>
              </div>
              <br />
              {alerting && errorMessage ?(
                <Alert
                  severity="error"
                  sx={{
                    width: matches ? "420px" : "310px",
                    marginTop: "20px",
                    mb: "20px",
                  }}
                >
                  {errorMessage}
                </Alert>
              ) : alerting ? <Alert
              severity="error"
              sx={{
                width: matches ? "420px" : "310px",
                marginTop: "20px",
                mb: "20px",
              }}
            >
              {t("All fields must be filled.")}
            </Alert> : null }
              <Button
                sx={{
                  backgroundImage:
                    "linear-gradient(to right, #64C6C6 , #008080)",
                  color: "white",
                  borderRadius: "10px",
                  textTransform: "none",
                  fontSize: "15px",
                  p: "5px",
                  width: "250px",
                  mt: "0px",
                  mb: "50px",
                }}
                onClick={handleSend}
              >
                {t('Submit')}
              </Button>
            </Stack>
          </Container>
        </div>
      )}
      <Success
        open={openSuccess}
        setOpen={setOpenSuccess}
        msg={"Your request has been successfully send."}
      />
      <Booking />
      <ContactIcon />
    </div>
  );
}
