import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useTranslation } from "react-i18next";
import TableField from "./TableField";
import { useSelector } from "react-redux";

export default function SecondSummeryTable({
  physician,
  setPhysician,
  setCalibration,
  calibration,
  hr,
  setHr,
  bp1,
  setBp1,
  bp2,
  setBp2,
  spo2,
  setSpo2,
  show,
  setReason,
  reason,
  diagnisis,
  setDiagnisis,
  recommendation,
  setRecommendation,
  extraNotes,
  setExtraNotes
}) {
  const { t } = useTranslation();
  const { type } = useSelector((state) => state.auth);

  return (
    <TableContainer
      component={Paper}
      sx={{ width: "100%", boxShadow: "none", direction: "ltr" }}
    >
      <Table
        sx={{
          border: "1.5px solid #008080",
          ".MuiTableCell-root": { fontFamily: '"Quicksand", sans-serif' },
        }}
      >
        <TableHead sx={{ bgcolor: "#008080" }}>
          <TableRow>
            <TableCell colSpan={4} sx={{ color: "white", fontSize: "15px" }}>
              {t("Summary and Conclusion")}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!show && diagnisis ? (
            <TableRow
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell
                component="th"
                scope="row"
                sx={{
                  color: "#464646",
                  fontSize: "15px",
                  fontWeight: "bold",
                  borderBottom: "none",
                }}
              >
                Diagnosis/Assessment
              </TableCell>
              <TableCell
                align="start"
                sx={{ alignItems: "start", borderBottom: "none" }}
                colSpan={3}
              >
                <p className="p_text">{diagnisis}</p>
              </TableCell>
            </TableRow>
          ) : null}

          {show ? (
            <TableRow
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell
                component="th"
                scope="row"
                sx={{
                  color: "#464646",
                  fontSize: "15px",
                  fontWeight: "bold",
                  borderBottom: "none",
                }}
              >
                Diagnosis/Assessment
              </TableCell>
              <TableCell
                align="start"
                sx={{ alignItems: "start", borderBottom: "none" }}
                colSpan={3}
              >
                <TableField
                  onChange={setDiagnisis}
                  value={diagnisis}
                  h={"100px"}
                  width={"100%"}
                  type={"text"}
                />
              </TableCell>
            </TableRow>
          ) : null}

          {!show && recommendation ? (
            <TableRow
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell
                component="th"
                scope="row"
                sx={{
                  color: "#464646",
                  fontSize: "15px",
                  fontWeight: "bold",
                  borderBottom: "none",
                }}
              >
                Recommendation
              </TableCell>
              <TableCell
                colSpan={3}
                align="start"
                sx={{ alignItems: "start", borderBottom: "none" }}
              >
                <p className="p_text">{recommendation}</p>
              </TableCell>
            </TableRow>
          ) : null}

          {show ? (
            <TableRow
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell
                component="th"
                scope="row"
                sx={{
                  color: "#464646",
                  fontSize: "15px",
                  fontWeight: "bold",
                  borderBottom: "none",
                }}
              >
                Recommendation
              </TableCell>
              <TableCell
                colSpan={3}
                align="start"
                sx={{ alignItems: "start", borderBottom: "none" }}
              >
                <TableField
                  onChange={setRecommendation}
                  value={recommendation}
                  h={"100px"}
                  width={"100%"}
                  type={"text"}
                />
              </TableCell>
            </TableRow>
          ) : null}

          {!show && extraNotes ? (
            <TableRow
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell
                component="th"
                scope="row"
                sx={{
                  color: "#464646",
                  fontSize: "15px",
                  fontWeight: "bold",
                  borderBottom: "none",
                }}
              >
                Extra Notes
              </TableCell>
              <TableCell
                colSpan={3}
                align="start"
                sx={{ alignItems: "start", borderBottom: "none" }}
              >
                <p className="p_text">{extraNotes}</p>
              </TableCell>
            </TableRow>
          ) : null}

          {show ? (
            <TableRow
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell
                component="th"
                scope="row"
                sx={{
                  color: "#464646",
                  fontSize: "15px",
                  fontWeight: "bold",
                  borderBottom: "none",
                }}
              >
                Extra Notes
              </TableCell>
              <TableCell
                colSpan={3}
                align="start"
                sx={{ alignItems: "start", borderBottom: "none" }}
              >
                <TableField
                  handle={true}
                  onChange={setExtraNotes}
                  value={extraNotes}
                  h={"100px"}
                  width={"100%"}
                  type={"text"}
                />
              </TableCell>
            </TableRow>
          ) : null}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
