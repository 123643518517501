import React from 'react'
import logo from "../Media/logo.png";
import { Box, Stack } from '@mui/material';

export default function SecondReportPdf({ requests, report, details }) {
  console.log(report)
  return (
    <div  
    id="pdf-content"
    style={{
        backgroundColor: "white",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "200mm",
        minHeight: "297mm",
        padding: "10mm",
        boxSizing: "border-box",
      }}>
      <img src={logo} alt="logo" style={{ width: "150px" }} />
      <div className="reusable">Patient Details</div>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          gap: "250px",
          width:'90%'
        }}
      >
        <div style={{ gap: "7px", display: "flex", flexDirection: "column" }}>
          <p>
            Patient Name: <strong>{requests && requests.patient}</strong>
          </p>
          <p>
            Patient Gender: <strong>{details && details[2].gender}</strong>
          </p>
          <p>
            Requested Service: <strong>{requests && requests.service}</strong>
          </p>
          <p>
          Diagnostics: <strong>{requests && requests.technician}</strong>
          </p>
          <p>
          Reason for the ECG: <strong>{details && details[0].birthdate}</strong>
          </p>
        </div>
        <div style={{ gap: "7px", display: "flex", flexDirection: "column" }}>
          <p>
            Patient ID: <strong>{details && details[3].id_number}</strong>
          </p>
          <p>
          Patient Birthdate: <strong>{details && details[0].birthdate}</strong>
          </p>
          {/* <p>
          ECG Machine Model: <strong>{requests && requests.start_date}</strong>
          </p> */}
          {/* <p>
          Number of leads: <strong>{requests && requests.supervisor}</strong>
          </p> */}
        </div>
      </div>
      <div className="reusable">General Information</div>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          flexDirection: "column",
          width: "90%",
        }}
      >
        <p>
          Referring Physician:
          <strong>{report && report.referring_physician}</strong>
        </p>
        <p>
        Calibration: <strong>{report && report.report_other.calibration_one / report.report_other.calibration_two}</strong>
        </p>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            flexDirection: "row",
            gap: "100px",
          }}
        >
          <p>
            HR: <strong>{report && report.hr}</strong>
          </p>
          <p>
            BP:
            <strong>
              {report && report.bp_1} / {report && report.bp_2}
            </strong>
          </p>
          <p>
            SPO2: <strong>{report && report.spo2}</strong>
          </p>
        </div>
      </div>

      <div className="reusable">ECG Interpretation</div>
      <div style={{
          display: "flex",
          flexWrap: "wrap",
          alignItems:'start',
          width: "90%",
        }}>
      <p>{report && report.report_other.interpretation}</p>
      </div>
      <div className="reusable">Summery and Conclusion</div>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          flexDirection: "column",
          width: "90%",
        }}
      >
        <p>
        Diagnosis/Assessment:
          <strong>{report && report.referring_physician}</strong>
        </p>
        <p>
        Recommendation: <strong>{report && report.indication}</strong>
        </p>
        <p>
        Extra Notes: <strong>{report && report.indication}</strong>
        </p>
      </div>

      <div className="reusable">Images</div>
      <div style={{display:'flex',flexWrap:'wrap',justifyContent:'center',alignItems:'start',gap:'20px'}}>
      {report.attachments.length > 0 && report.attachments.map((image)=>
      <img src={image.attachment} alt="logo" style={{ width: "200px",height:'150px' }} />
      )}
      </div>
      <div style={{ width: '90%', height: '1px', backgroundColor: 'black', margin: '0 auto', marginTop:'50px' }}></div>
       <Stack direction='row'  sx={{
                        displa: "flex",
                        flexWrap: "wrap",
                        justifyContent: "center",
                        alignItems: "center",
                        mt:'10px'
                      }}
                      gap={20}>
      <Box><p><strong>Technician:</strong>{requests && requests.technician}</p></Box>
      <Box><p><strong>Approved By:</strong>{requests && requests.supervisor}</p></Box>
      </Stack>
    </div>
  )
}
