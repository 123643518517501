import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useTranslation } from "react-i18next";
import TableField from "./TableField";
import { useSelector } from "react-redux";

export default function ECGTable({
  ECG,
  setECG,
  show,
}) {
  const { t } = useTranslation();
  const { type } = useSelector((state) => state.auth);

  return (
    <TableContainer
      component={Paper}
      sx={{ width: "100%", boxShadow: "none", direction: "ltr" }}
    >
      <Table
        sx={{
          border: "1.5px solid #008080",
          ".MuiTableCell-root": { fontFamily: '"Quicksand", sans-serif' },
        }}
      >
        <TableHead sx={{ bgcolor: "#008080" }}>
          <TableRow>
            <TableCell colSpan={4} sx={{ color: "white", fontSize: "15px" }}>
              {t("ECG interpretation")}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!show && ECG ? (
            <TableRow
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell
                align="start"
                sx={{ alignItems: "start", borderBottom: "none" }}
                colSpan={3}
              >
                <p className="p_text">{ECG}</p>
              </TableCell>
            </TableRow>
          ) : null}

          {show ? (
            <TableRow
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell
                align="start"
                sx={{ alignItems: "start", borderBottom: "none" }}
                colSpan={3}
              >
                <textarea
                  value={ECG}
                  onChange={(e)=>{setECG(e.target.value)}}
                />
              </TableCell>
            </TableRow>
          ) : null}

        </TableBody>
      </Table>
    </TableContainer>
  );
}
