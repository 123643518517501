import React, { useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import Navbar from "./Navbar";
import Footer from "./Footer";
import SecondFooter from "./SecondFooter";
import MainPage from "./Pages/MainPage";
import PrivacyPolice from "./Pages/PrivacyPolice";
import InnerService from "./Pages/InnerService";
import RequestService from "./Pages/RequestService";
import JoinNetwork from "./Pages/JoinNetwork";
import Login from "./Pages/Login";
import ForgotPassword from "./Pages/ForgotPassword";
import ResetPassword from "./Pages/ResetPassword";
import SecondNavbar from "./SecondNavbar";
import TechnicianPage from "./Pages/TechnicianPage";
import Profile from "./Pages/Profile";
import RequestNewService from "./Pages/RequestNewService";
import AddPatient from "./Pages/AddPatient";
import Report from "./Pages/Report";
import PendingReports from "./Pages/PendingReports";
import RequireAuth from "./RequireAuth";
import DicomViewer from './Pages/DicomViewer';
import SecondReportPdf from "./Components/SecondReportPdf";

function Layout() {
  const location = useLocation();
  const showStandardLayout =
    location.pathname !== "/login" &&
    location.pathname !== "/forgotpassword" &&
    location.pathname !== "/resetpassword" &&
    location.pathname !== "/mainpage" &&
    location.pathname !== "/profile" &&
    location.pathname !== "/requestnewservice" &&
    location.pathname !== "/addpatient" &&
    location.pathname !== "/report" &&
    location.pathname !== "/pendingreports"&&
    location.pathname !== "/dicom"&&
    location.pathname !== 'pdf'



  const showSecondNav =
    location.pathname !== "/mainpage" &&
    location.pathname !== "/profile" &&
    location.pathname !== "/requestnewservice" &&
    location.pathname !== "/addpatient" &&
    location.pathname !== "/report" &&
    location.pathname !== "/pendingreports"&&
    location.pathname !== "/dicom"&&
    location.pathname !== 'pdf'

  const dicomShow =   location.pathname !== "/dicom";

  return (
    <>
      {showSecondNav ? <Navbar /> : dicomShow ? <SecondNavbar /> : null }
      <Routes>
        <Route path="/" element={<MainPage />} />
        <Route path="/privacypolicy" element={<PrivacyPolice />} />
        <Route path="/service/:id" element={<InnerService />} />
        <Route path="/requestservice/:id" element={<RequestService />} />
        <Route path="/requestservice" element={<RequestService />} />
        <Route path="/joinus" element={<JoinNetwork />} />
        <Route path="/login" element={<Login />} />
        <Route path="/forgotpassword" element={<ForgotPassword />} />
        <Route path="/password/reset" element={<ResetPassword />} />
        <Route path="/pdf" element={<SecondReportPdf />} />
        <Route
          path="/mainpage"
          element={
            <RequireAuth role={["technician", "supervisor", "doctor"]}>
              <TechnicianPage />
            </RequireAuth>
          }
        />
        <Route
          path="/pendingreports"
          element={
            <RequireAuth role={["technician", "supervisor"]}>
              <PendingReports />
            </RequireAuth>
          }
        />
        <Route
          path="/profile"
          element={
            <RequireAuth
              role={["technician", "supervisor", "patient", "doctor"]}
            >
              <Profile />
            </RequireAuth>
          }
        />
        <Route
          path="/requestnewservice"
          element={
            <RequireAuth role={["patient", "doctor"]}>
              <RequestNewService />
            </RequireAuth>
          }
        />
        <Route
          path="/addpatient"
          element={
            <RequireAuth role={["doctor"]}>
              <AddPatient />
            </RequireAuth>
          }
        />
        <Route
          path="/report"
          element={
            <RequireAuth
              role={["technician", "supervisor", "patient", "doctor"]}
            >
              <Report />
            </RequireAuth>
          }
        />
         <Route
          path="/dicom"
          element={
            <RequireAuth
              role={["technician", "supervisor", "patient", "doctor"]}
            >
              <DicomViewer />
            </RequireAuth>
          }
        />
      </Routes>
      {showStandardLayout ? <Footer /> : dicomShow ? <SecondFooter /> : null }
    </>
  );
}

export default Layout;
